import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../enums';

const Component = () => import('./GlobalMenuGroupList.vue');

export default {
  path: buildChildURL(GlobalMenuGroupsRoutePathNames.LIST),
  name: GlobalMenuGroupsRouteNames.GLOBAL_MENU_GROUPS_LIST,
  component: Component,
} as RouteRecordRaw;
