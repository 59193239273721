import { useSiteStore } from '@centric-os/stores';
import type { BusinessUnitsKDS } from '@centric-os/types';
import ID from '@compassdigital/id';
import { filter, isEmpty, isNil, map } from 'lodash';
import { defineStore, storeToRefs, type StateTree } from 'pinia';

interface State extends StateTree {
  businessUnits: BusinessUnitsKDS | null;
}

/**
 * Creates a Pinia store for managing KDS devices across business units.
 *
 * @param {string} [storeId='kds'] - The unique ID of the store.
 * @returns {import('pinia').Store} - The Pinia store instance containing KDS device data.
 */
export function useKDSStore(storeId = 'kds') {
  const store = defineStore(storeId, {
    state: (): State => ({
      businessUnits: null, // State holding business units with KDS devices
    }),
    actions: {
      /**
       * Fetches KDS devices from the backend for the current site's business units.
       * It first retrieves the site's locations and checks if the site is a Canadian unit.
       * It then fetches the KDS devices associated with the business units.
       *
       * @returns {Promise<void>} - A promise that resolves when the KDS devices are fetched and the store is updated.
       */
      async fetchKDSDevices(): Promise<void> {
        const siteStore = useSiteStore();
        const { site } = storeToRefs(siteStore);

        // If site data is not available, clear the businessUnits state
        if (isNil(site.value)) {
          this.businessUnits = null;
          return;
        }

        // Determine if the site is a Canadian unit
        const isCanadianUnit = site.value?.address?.country?.toLowerCase().startsWith('ca');

        // Extract unit IDs for each location
        const unitIds = filter(
          map(site.value?.locations, (location) => {
            const unitId = location?.meta?.unit_id;
            return isCanadianUnit ? `ca${unitId}` : unitId;
          }),
        );

        // If unit IDs are present, fetch the corresponding KDS devices
        if (!isNil(unitIds) && !isEmpty(unitIds)) {
          const response = await this.cdlApi.get<BusinessUnitsKDS>('/kds/devices', {
            params: { unitNumber: unitIds.join(',') },
          });

          // Update the businessUnits state with fetched KDS devices
          this.businessUnits = response.data;
        } else {
          // Clear the businessUnits state if no unit IDs are found
          this.businessUnits = null;
        }
      },

      /**
       * Resets the token for a specific KDS device based on its serial number.
       *
       * @param {string} serialNumber - The serial number of the KDS device.
       * @returns {Promise<void>} - A promise that resolves when the device token has been successfully reset.
       */
      async resetDeviceToken(serialNumber: string): Promise<void> {
        if (serialNumber) {
          const id = ID('kds', 'cdl', 'device', serialNumber);
          // Send a request to delete the device's auth token by its unique ID
          await this.cdlApi.delete(`/kds/device/auth/${id}`);
        } else {
          return Promise.reject(new Error('no device is selected'));
        }
      },
    },
    pagination: false,
  });
  return store();
}
