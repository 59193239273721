import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LibrariesRouteNames, LibrariesRoutePathNames } from '../../enums';
import verifiedItemsRoute from './components/cpg-verified-items';
import pendingItemsRoute from './components/cpg-pending-items';

export default {
  path: buildChildURL(LibrariesRoutePathNames.CPG_ITEMS_LIST),
  name: LibrariesRouteNames.CPG_ITEMS,
  children: [verifiedItemsRoute, pendingItemsRoute],
} as RouteRecordRaw;
