<template>
  <Dialog
    @hide="onHideScannerDialog"
    :visible="showScannerDialog"
    :closable="false"
    :class="'w-11'"
    :pt="{
      header: {
        style: {
          background: 'transparent',
        },
      },
      content: {
        style: {
          background: 'transparent',
        },
      },
      root: {
        style: {
          background: 'linear-gradient(180deg, #FFF2E1 0%, #FCFAFF 51.5%, #F0FBFF 100%) !important',
        },
      },
    }"
  >
    <template #header>
      <div class="flex align-items-center justify-content-center relative w-full">
        <div class="flex text-center text-lg font-semibold">
          {{ translate('barcodeScannerDialog.title') }}
        </div>
        <i
          icon="pi pi-times"
          :class="`material-symbols-rounded ${ButtonStateClass.TEXT} absolute right-0`"
          @click="onHideScannerDialog"
          >close</i
        >
      </div>
    </template>
    <div class="flex justify-content-center align-items-center text-center w-100">
      <p>{{ translate('barcodeScannerDialog.content') }}</p>
    </div>
    <Image :src="barcode_scanner" alt="Scan barcode image" class="w-full mt-2" />
    <div class="grid mt-2">
      <div class="col-6">
        <Button
          type="button"
          class="w-full"
          :class="`${ButtonStateClass.TEXT} `"
          :label="translate('barcodeScannerDialog.closeButtonLabel')"
          @click="onHideScannerDialog"
        />
      </div>
      <div class="col-6">
        <Button
          class="w-full"
          type="button"
          :label="translate('barcodeScannerDialog.redirectButtonLabel')"
          @click="onRedirectToScanner"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useTranslation } from '@centric-os/helpers';
import { useRouter } from 'vue-router';
import { useAuthStore, useSplitIoStore } from '@centric-os/stores';
import { FeatureFlags } from '@/types';
import { barcode_scanner } from '@centric-os/components/src/assets/index';
import { ButtonStateClass } from '@/components/src';
import { ScannerRouteNames } from '@centric-os/scanner';

const splitIoStore = useSplitIoStore();
const authStore = useAuthStore();
const router = useRouter();
const showScannerDialog = ref(false);
const { translate } = useTranslation();

const shouldShowDialog = computed(
  () => authStore.loggedInOnMobile && splitIoStore.isFeatureOn(FeatureFlags.SCANNER),
);

const showDialog = () => {
  if (shouldShowDialog.value) showScannerDialog.value = true;
};

const onHideScannerDialog = () => (showScannerDialog.value = false);

const onRedirectToScanner = () => {
  router.push({ name: ScannerRouteNames.SCANNER_ROOT });
  showScannerDialog.value = false;
};

watch(shouldShowDialog, (newVal) => {
  if (newVal) showDialog();
});
</script>
