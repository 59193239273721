import { computed, inject, type InjectionKey, ref, type Ref } from 'vue';
import type { RouteRecordName, RouteLocationRaw } from 'vue-router';

export const UserRouteNameKey: InjectionKey<RouteRecordName> = Symbol('userRouterName');

export const useUserLink = (id: string | Ref<string>) => {
  const routeName = inject<RouteRecordName>(UserRouteNameKey as symbol);

  if (!routeName) {
    console.warn("No inject for 'userRouterName'. Provide in parent component");
    return;
  }

  const userId = ref(id);

  const link = computed<RouteLocationRaw>(() => {
    return routeName && userId.value
      ? ({
          name: routeName,
          params: {
            id: userId.value,
          },
        } as RouteLocationRaw)
      : undefined;
  });

  return link;
};
