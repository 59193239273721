<template>
  <PVConfirmDialog :blockScroll="true" :dismissableMask="true" :draggable="false" v-bind="$attrs">
    <template v-if="$slots.icon" #icon>
      <slot name="icon"></slot>
    </template>
  </PVConfirmDialog>
</template>

<script setup lang="ts">
import PVConfirmDialog from 'primevue/confirmdialog';
</script>

<script lang="ts">
export default {
  name: 'ConfirmDialog',
  inheritAttrs: false,
};
</script>
