<template>
  <div class="c-field">
    <slot v-if="$slots.label" name="label" />
    <label class="c-field-label font-semibold" v-else>{{ label }}</label>

    <div class="mt-2">
      <slot v-if="$slots.value" name="value" />
      <span class="c-field-value">{{ value }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  label: {
    type: String,
  },
  value: null,
});
</script>

<script lang="ts">
export default { name: 'Field' };
</script>

<style scoped lang="scss"></style>
