import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../../enums';

const Component = () => import('./MenuHours.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.MENU_HOURS),
  name: SitesRouteNames.SITE_MENU_HOURS,
  component: Component,
} as RouteRecordRaw;
