<template>
  <div
    class="c-image-upload flex flex-column align-items-start w-full p-field"
    :class="[{ 'p-error': errorMessage }]"
  >
    <label v-if="$slots.label" class="c-image-upload-label subtitle-2">
      <slot name="label" />
    </label>

    <FileUpload
      customUpload
      ref="uploader"
      :accept="accept"
      :maxFileSize="maxFileSize"
      :chooseLabel="chooseLabel"
      :invalidFileSizeMessage="
        t('errorMessages.fileSize', { maxFileSize }, 'File cannot be bigger than {maxFileSize}')
      "
      :invalidFileTypeMessage="t('errorMessages.fileType', 'File format not supported')"
      @select="onFileSelected"
      :disabled="disabled"
    />
    <div class="c-image-upload-file-container relative w-full mt-2 h-15rem">
      <Image :src="imageSrc" class="c-image-upload-image w-full h-full" v-if="imageSrc" />

      <div
        v-if="!hasImage"
        class="c-image-upload-drag-message top-0 left-0 absolute h-full w-full justify-content-center align-items-center subtitle-2"
      >
        <span>{{ dragMessage }}</span>
      </div>
      <div
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
        @click="callFileUpload"
        :class="[
          'c-drop-zone-border w-full h-full absolute top-0 lef-0 border-1 border-dashed border-primary flex align-items-center justify-content-center flex-column',
          { 'c-image-upload-highlight': dragOver },
          { 'c-image-upload-drop-zone': !imageSrc },
          { 'cursor-pointer': !disabled },
          { 'cursor-not-allowed': disabled },
        ]"
      >
        <template v-if="!imageSrc">
          <span class="material-symbols-rounded p-4 text-6xl text-primary text-error"
            >file_upload</span
          >
          <h2 class="text-primary text-error">{{ emptyMessage }}</h2>
        </template>
      </div>
    </div>

    <div class="w-full">
      <Button
        v-if="hasImage"
        :label="t('imageSelect.remove', 'Remove image')"
        :class="`c-button-text mt-2 w-full ${ButtonStateClass.OUTLINE} ${ButtonSeverityClass.DANGER}`"
        @click="onRemoveClicked"
        :disabled="disabled"
      />
      <span class="caption info-message mt-2" v-else>{{ infoMessage }}</span>
    </div>

    <div class="p-error mt-2" v-if="errorMessage">
      <span class="caption">{{ errorMessage }}</span>
    </div>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { FileUpload } from './../../../core/file/file-upload';
import { Image } from './../../../core/media';
import { Button, ButtonSeverityClass, ButtonStateClass } from './../../../core/buttons';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  accept: {
    type: String,
    default: 'image/png, image/jpeg, image/jpg',
  },
  maxFileSize: {
    type: Number,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  useS3bucket: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['select', 'removeSrc', 'removeFile', 'fileDropped']);

const uploader = ref(null);
// const selectedFile = ref(null);
const dragOver = ref(false);
const errorMessage = ref(null);
const { t } = useI18n();

const selectedFile = defineModel<any>('file');
const srcUrl = defineModel<any>('src');

watch(
  () => uploader.value?.messages,
  (newMessages, oldMessages) => {
    if (newMessages !== oldMessages && newMessages?.length) {
      errorMessage.value = newMessages[0];
    }
  },
);

const chooseLabel = computed(() =>
  t(
    `buttons.${selectedFile.value ? 'change' : 'select'}`,
    selectedFile.value ? 'Change' : 'Select',
  ),
);

const hasImage = computed(() =>
  Boolean(selectedFile.value?.objectURL || selectedFile.value || srcUrl.value),
);

const imageSrc = computed(() => {
  return selectedFile.value?.objectURL || validateSelectImage() || srcUrl.value;
});

const validateSelectImage = (): string => {
  return selectedFile.value ? setAWSS3BucketPrefix() : null;
};

const setAWSS3BucketPrefix = (): string => {
  if (props.useS3bucket) {
    return import.meta.env.VITE_AWS_BUCKET + selectedFile.value;
  } else {
    return selectedFile.value;
  }
};

const dragMessage = computed(() =>
  t('imageSelect.dragMessage', 'Drag and drop .png, .jpg or .jpeg'),
);

const emptyMessage = computed(() => t('imageSelect.emptyMessage', 'Upload image'));

const infoMessage = computed(() =>
  t('imageSelect.infoMessage', 'You can upload jpg, jpeg and png'),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onDragEnter = (event: Event) => {
  event.stopPropagation();
  event.preventDefault();
};

const onDragOver = (event: Event) => {
  if (!props.disabled) {
    event.stopPropagation();
    event.preventDefault();
    dragOver.value = true;
  }
};

const onDragLeave = () => {
  dragOver.value = false;
};

const onDrop = (event: Event) => {
  if (!props.disabled) {
    event.stopPropagation();
    event.preventDefault();

    dragOver.value = false;
    errorMessage.value = '';

    const files =
      (event as DragEvent).dataTransfer?.files || (event.target as HTMLInputElement).files;

    if (files?.length === 1) {
      uploader.value.onFileSelect(event);
      emit('fileDropped');
    } else {
      errorMessage.value = t('errorsMessages.fileLimit', 'Only one file can be selected');
    }
  }
};

const onFileSelected = ({ files }: { files: any[] }) => {
  const file = files.length ? files[files.length - 1] : null;

  if (file) {
    selectedFile.value = file;
    errorMessage.value = '';

    if (files.length > 1) {
      uploader.value.remove(0);
    }

    removeSrc();

    emit('select', file);
  }
};

const removeSrc = () => {
  if (srcUrl.value) {
    srcUrl.value = null;
    emit('removeSrc');
  }
};

const removeFile = () => {
  if (selectedFile.value) {
    selectedFile.value = null;
    emit('removeFile');
  }
};

const onRemoveClicked = () => {
  errorMessage.value = null;
  uploader.value.clear();

  removeFile();
  removeSrc();
};

const callFileUpload = () => {
  uploader.value.choose();
};
</script>

<style lang="scss" scoped>
.cursor-not-allowed {
  cursor: not-allowed !important;
}

:deep(.p-fileupload) {
  .p-fileupload-choose .p-button-icon::before {
    content: '\e934';
  }

  .p-fileupload-content,
  .p-fileupload-buttonbar button.p-button {
    display: none;
  }

  .p-fileupload-buttonbar {
    display: none;
  }
}

.c-image-upload-drop-zone {
  background-color: #f5f9ff;
}

.info-message {
  color: #757575;
}

.c-image-upload {
  &-image {
    aspect-ratio: 4/3;
    object-fit: contain;
  }

  &-drag-message {
    display: none;
  }

  &-highlight {
    background-color: var(--neutral-light-color);
    opacity: 0.3;
  }
}
</style>
