import { computed, inject, type InjectionKey, ref, type Ref } from 'vue';
import type { RouteRecordName, RouteLocationRaw } from 'vue-router';

export const RoleRouteNameKey: InjectionKey<RouteRecordName> = Symbol('roleRouterName');

export const useRoleLink = (id: string | Ref<string>) => {
  const routeName = inject<RouteRecordName>(RoleRouteNameKey as symbol);

  if (!routeName) {
    console.warn("No inject for 'userRouterName'. Provide in parent component");
    return;
  }

  const roleId = ref(id);

  const link = computed<RouteLocationRaw>(() => {
    return routeName && roleId.value
      ? ({
          name: routeName,
          params: {
            id: roleId.value,
          },
        } as RouteLocationRaw)
      : undefined;
  });

  return link;
};
