<template>
  <span v-for:="role in roles">
    <router-link :to="role.to">
      <Button :label="role.name" :class="['p-button-link white-space-nowrap']" />
    </router-link>
  </span>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { Role } from '@centric-os/types';
import { useRoleLink } from '@centric-os/helpers';
import { Button } from '../../../../core/buttons';

const props = defineProps({
  roles: {
    type: Array as PropType<Role[]>,
    required: true,
    default: () => [] as Role[],
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const roles = computed<Role[]>(() => {
  const finalIndex = props.roles.length - 1;
  const unsortedRolesLinks = props.roles;
  const sortedRoleLinks = unsortedRolesLinks.toSorted((roleA, roleB) =>
    roleA.name > roleB.name ? 1 : -1,
  );
  const modifiedRoles = sortedRoleLinks.map((role, index) => {
    const to = useRoleLink(role.id as string);
    const returnObj = {
      ...role,
      to,
    };
    if (index < finalIndex) returnObj.name = returnObj.name + ',';
    return returnObj;
  });
  return modifiedRoles;
});
</script>
