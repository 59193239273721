<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVDropdown
      v-model="value"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      ref="dropdown"
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot v-if="slot" :name="slot" v-bind="scope" />
      </template>

      <template v-if="$slots.header && !customFilter" v-slot:header="{ options, value }">
        <slot name="header" :options="options" :value="value"></slot>
      </template>

      <template #dropdownicon>
        <i class="material-symbols-rounded"> expand_more </i>
      </template>
    </PVDropdown>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, useAttrs } from 'vue';
import PVDropdown from 'primevue/dropdown';
import { useInputState } from '../composables';

const props = defineProps({
  customFilter: Boolean,
});

const dropdown = ref<InstanceType<typeof PVDropdown>>();

const attrs = useAttrs();

const value = defineModel<any>();

const { focused, hovered, classes: rootClasses } = useInputState(attrs);

defineExpose({ dropdown });
</script>
