// import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { ScannerRoutePathNames, ScannerRouteNames } from './enums';
import { OrdersRouteNames } from '@centric-os/orders';
import { useMainNavigationStore } from '@centric-os/stores';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(ScannerRoutePathNames.SCANNER),
  name: ScannerRouteNames.SCANNER_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  // children: routes,
};
