<template>
  <InputSwitch
    v-bind="$attrs"
    v-model="value"
    :id="id"
    trueValue="active"
    falseValue="inactive"
    :required="true"
  >
    <template v-if="label" #label>{{ label }}</template>
    <template v-if="helperText" #helperText>{{ helperText }}</template>
  </InputSwitch>
</template>

<script setup lang="ts">
import { InputSwitch } from './../input-switch';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: String,
  helperText: String,
});

const value = defineModel<any>();
</script>
