import { defineComponent, h, resolveComponent } from 'vue';
import { LinkCell } from '../../../../core/data';
import { useUserLink } from '@centric-os/helpers';

export const UserLinkCell = defineComponent({
  components: {
    LinkCell,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const to = useUserLink(props.id as string);

    return () =>
      h(resolveComponent('LinkCell'), {
        buttonClass: 'capitalize',
        label: props.name,
        to: to.value,
      });
  },
});
