<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <AutoCompleteBox
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        :label="label"
        v-bind="$attrs"
        ref="box"
      >
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </AutoCompleteBox>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { AutoCompleteBox } from './../../../core/controls';
import { FieldValidator } from './../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
});

const box = ref<InstanceType<typeof AutoCompleteBox>>();

defineExpose({ box });
</script>
