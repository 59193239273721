import type { Calendar, CalendarOptions } from '@fullcalendar/core';
import type FullCalendar from '@fullcalendar/vue3';
import { DateTime } from 'luxon';
import { computed, nextTick, type Ref } from 'vue';

export default (
  fullCalendarRef: Ref<InstanceType<typeof FullCalendar>>,
  fullCalendarOptions: Ref<CalendarOptions>,
  currentCalendarMonth: Ref<String>,
) => {
  const fullCalendarViews = {
    WEEK: 'Week',
    WEEKDAY: 'Weekday',
    DAY: 'Day',
  };

  const calendarViews = computed(() => {
    return [fullCalendarViews.WEEK, fullCalendarViews.WEEKDAY, fullCalendarViews.DAY];
  });
  const getMonths = () => {
    const today = DateTime.local().startOf('month');
    const last36Months = [];
    const next36Months = [];

    for (let i = 36; i >= 1; i--) {
      const lastMonth = today.minus({ months: i });
      last36Months.push(lastMonth.toFormat('MMMM yyyy'));
    }

    for (let i = 0; i <= 36; i++) {
      const nextMonth = today.plus({ months: i });
      next36Months.push(nextMonth.toFormat('MMMM yyyy'));
    }

    return [...last36Months, ...next36Months];
  };

  const syncDate = () => {
    const api = fullCalendarRef?.value.getApi() as Calendar;
    const current = api.getCurrentData();
    const currentDateObj = DateTime.fromJSDate(current.dateProfile.currentDate);
    const formattedMonth = currentDateObj.toFormat('MMMM yyyy');
    currentCalendarMonth.value = formattedMonth;
  };
  const goToMonth = (data) => {
    const [month, year] = data.value.split(' ');
    const targetMonth = DateTime.fromObject({
      year: year,
      month: DateTime.fromFormat(month, 'LLLL').month,
    });
    const api = fullCalendarRef.value?.getApi() as Calendar;
    let nextDay = 0;
    if (!fullCalendarOptions.value.weekends && targetMonth.weekday === 6) {
      // If 5 day view
      nextDay = 1; // If Sunday
      // On 5 day view, if 1st of month is on Sunday, it will display previous week
    }
    api.gotoDate(targetMonth.plus({ days: nextDay }).toMillis());
  };
  const toggleCalendarView = async (event) => {
    const calendarView = event?.value;
    const api = fullCalendarRef?.value?.getApi() as Calendar;
    if (calendarView !== fullCalendarViews.DAY) {
      const showWeekend = Boolean(calendarView === fullCalendarViews.WEEK);
      fullCalendarOptions.value.weekends = showWeekend;
      await nextTick();
      fullCalendarOptions.value.initialView = 'timeGridWeek';
      api.changeView('timeGridWeek');
    } else {
      fullCalendarOptions.value.initialView = 'timeGridDay';
      api.changeView('timeGridDay');
    }
    syncDate();
  };
  const goToPrevPage = () => {
    const api = fullCalendarRef?.value.getApi() as Calendar;
    api.prev();
    syncDate();
  };
  const goToNextPage = () => {
    const api = fullCalendarRef?.value.getApi() as Calendar;
    api.next();
    syncDate();
  };

  const goToTodaysPage = () => {
    const api = fullCalendarRef?.value.getApi() as Calendar;
    api.today();
    syncDate();
  };

  return {
    getMonths,
    fullCalendarViews,
    calendarViews,
    syncDate,
    goToMonth,
    toggleCalendarView,
    goToPrevPage,
    goToNextPage,
    goToTodaysPage,
  };
};
