import { FeatureFlags } from '@centric-os/types';
import { useBreakpoints } from '@centric-os/helpers';
import { useSplitIoStore } from '@centric-os/stores';
import { useElementSize } from '@vueuse/core';
import { computed, nextTick, ref } from 'vue';
export default (props) => {
  // Comp refs
  const sidebarHeaderRef = ref(null);
  const sidebarFooterRef = ref(null);
  const sidebarActionBarRef = ref(null);
  const sidebarScrollRef = ref(null);

  const { isFeatureOn } = useSplitIoStore();
  const showContentSlot = ref<boolean>(!props.tabItems?.length);
  const displayContentSlot = (): void => {
    if (props.tabItems?.length && isFeatureOn(FeatureFlags.MENU_SIDEBAR_UI_UPDATES)) {
      showContentSlot.value = true;
    }
  };
  const displayTabs = (): void => {
    if (props.tabItems?.length && isFeatureOn(FeatureFlags.MENU_SIDEBAR_UI_UPDATES)) {
      showContentSlot.value = false;
    }
  };

  const { height: headerHeight } = useElementSize(sidebarHeaderRef);
  const { height: footerHeight } = useElementSize(sidebarFooterRef);
  const { width: sideBarActionBarWidth } = useElementSize(sidebarActionBarRef);

  const rootClasses = ref<string>(`c-sidebar-form p-sidebar-right`);

  const contentPadding = computed<string>(() => {
    // NOTE: This px-5 makes thing not aligned with our standard paddings
    // if (props.large) {
    //   return 'px-5';
    // }
    return 'px-3';
  });

  const getSidebarStyle = computed<string>(() => {
    const toolbar: HTMLElement = document.querySelector('#title-bar');
    const toolbarHeight = toolbar?.offsetHeight || 0;
    return `height: calc(100vh - ${toolbarHeight}px); margin-top: ${toolbarHeight}px`;
  });

  const getContentHeight = computed<string>(() => {
    // Footer has dynamic height so we need to calc the difference for the content height so overflow work properly
    const toolbar: HTMLElement = document.querySelector('#title-bar');
    const toolbarHeight = toolbar?.offsetHeight || 0;
    const tabHeaderHeight = !showContentSlot.value ? 48 : 0; // 48 for Tab header
    const formToContentDiff = toolbarHeight + headerHeight.value + tabHeaderHeight;
    return `height: calc(100vh - ${formToContentDiff}px - ${footerHeight.value}px)`;
  });
  const getLeftColumnStyle = computed<string>(() => {
    return `width: calc(100% - ${sideBarActionBarWidth.value}px)`;
  });

  const setSidebarSizeByBreakpoints = async (): Promise<void> => {
    const breakpoints = useBreakpoints();

    await nextTick();
    if (props.large) {
      if (breakpoints.isGreater('xl')) {
        rootClasses.value = `${rootClasses.value} w-6`;
      } else {
        rootClasses.value = `${rootClasses.value} p-sidebar-size-mobile-tablet-large`;
      }
    } else {
      if (breakpoints.isGreater('md')) {
        rootClasses.value = `${rootClasses.value} p-sidebar-size-laptop `;
      } else {
        rootClasses.value = `${rootClasses.value} p-sidebar-size-mobile-tablet`;
      }
    }
  };

  return {
    rootClasses,
    getSidebarStyle,
    contentPadding,
    sidebarHeaderRef,
    sidebarFooterRef,
    sidebarScrollRef,
    sidebarActionBarRef,
    headerHeight,
    footerHeight,
    sideBarActionBarWidth,
    getContentHeight,
    getLeftColumnStyle,
    showContentSlot,
    setSidebarSizeByBreakpoints,
    displayContentSlot,
    displayTabs,
  };
};
