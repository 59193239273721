export enum GlobalMenuGroupsRoutePathNames {
  BRANDS = 'brands',
  GLOBAL_MENU_GROUPS = 'global-menus',
  BRAND_DETAILS = 'brand',
  LIST = '',
  ID = ':id',
  BRAND_ID = ':brandId',
  MENUS = 'menus',
  MENUS_ID = ':menuId?',
  ITEMS = 'items',
  MODIFIERS = 'modifiers',
}
