import { defineComponent, h, resolveComponent } from 'vue';
import { Button } from '../button';
import { ButtonStateClass } from '../enums';

export const TextButton = defineComponent({
  name: 'TextButton',
  components: { Button },
  setup() {
    return () =>
      h(resolveComponent('Button'), {
        class: [ButtonStateClass.TEXT],
      });
  },
});
