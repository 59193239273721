import { defineStore, type StateTree } from 'pinia';

interface State extends StateTree {
  contentPageHeaderHeight: number;
}

export const useContentPageStore = defineStore('contentPage', {
  state: (): State => ({
    contentPageHeaderHeight: 0,
  }),
  getters: {
    contentPageLayoutHeight: (state) => `calc(100vh - ${state.contentPageHeaderHeight}px)`,
  },
});
