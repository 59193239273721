import type { InjectionKey } from 'vue';
import type { Emitter } from '@centric-os/types';
import EmitterInit from '@centric-os/platform/emitter';

export type GlobalEvents = {
  toggleSidebar?: boolean;
  toggleHeader?: boolean;
};

export const GlobalEmitterKey: InjectionKey<Emitter<GlobalEvents>> = Symbol('GlobalEmitter');

export default EmitterInit<GlobalEvents>();
