<template>
  <div class="grid-nogutter flex flex-wrap grid-height">
    <div class="col-12">
      <Card
        id="main-content-card"
        class="main-content-card"
        :class="[cardClassesHelper.NO_SHADOWS, fullViewport ? cardClassesHelper.FULL_VIEWPORT : '']"
      >
        <template #content>
          <div class="splitter-height">
            <Splitter class="h-auto min-h-full max-h-full">
              <slot name="content" />
            </Splitter>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useContentPageStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';
import { Card, CardHelperClasses, Splitter } from '../../containers';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fullViewport: {
    type: Boolean,
    default: false,
  },
});

const cardClassesHelper = CardHelperClasses;

const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);
</script>

<style lang="scss" scoped>
// .grid-height {
//   height: v-bind("contentPageLayoutHeight");
// }

.splitter-height {
  height: v-bind('contentPageLayoutHeight');
  max-height: v-bind('contentPageLayoutHeight');
}

:deep(.p-splitter) {
  border: none;
}

:deep(.p-card) {
  &.card-full-viewport {
    height: v-bind('contentPageLayoutHeight');
    max-height: v-bind('contentPageLayoutHeight');

    .p-card-body {
      max-height: v-bind('contentPageLayoutHeight');
      // overflow-y: auto;
      padding: 0;
    }
  }
}
</style>
