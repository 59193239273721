<template>
  <slot :fields="fields" :remove="remove" :push="push" :update="update" v-bind="fieldProps" />
</template>

<script setup lang="ts">
import { useFieldArray } from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  validateOnValueUpdate: {
    type: Boolean,
    default: true,
  },
});

const { remove, push, update, fields, ...fieldProps } = useFieldArray(props.name as string);
</script>
