<template>
  <teleport :to="`#${id}`">
    <SaveBarForm v-bind="{ ...formProps, ...$attrs }" />
  </teleport>
</template>

<script setup lang="ts">
import { onBeforeUnmount, watchEffect, computed, onErrorCaptured } from 'vue';
import { useHeaderBar } from './../header-bar/hooks';
import SaveBarForm from './SaveBarForm.vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  title: String,
  saveDisabled: Boolean,
  discardDisabled: Boolean,
});

const { id, toggleHeader } = useHeaderBar();
const formProps = computed(() => {
  const { ...newProps } = props;
  delete newProps.show;
  return newProps;
});

watchEffect(() => toggleHeader(props.show));

onBeforeUnmount(() => {
  toggleHeader(false);
});

onErrorCaptured(() => {
  console.error('error captured on event handler');
  return false;
});
</script>
