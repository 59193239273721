<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, handleBlur, errorMessage }">
      <MultiSelect
        :modelValue="value"
        @update:modelValue="handleChange"
        @blur="handleBlur"
        :class="{ 'p-invalid': errorMessage }"
        :virtualScrollerOptions="virtualScrollerOptions"
        v-bind="$attrs"
        ref="multiSelect"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="errorMessage" #helperText>{{ errorMessage }}</template>
      </MultiSelect>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { MultiSelect } from './../../../core/controls';
import { FieldValidator } from './../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  virtualScrollerOptions: {
    type: Object,
  },
});

const multiSelect = ref<InstanceType<typeof MultiSelect>>();

defineExpose({ multiSelect });
</script>
