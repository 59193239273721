import type { ComputedRef } from 'vue';
import type { LocationQueryValueRaw } from 'vue-router';
import type { User } from '../user';

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  IN_PROGRESS = 'in_progress',
}

export type RecordEntityId = string | number;
export type RecordName = string;

export type RecordUniqueProperty = RecordName | RecordEntityId;

export interface RecordEntity {
  id?: RecordEntityId;
  createdAt?: string | number | Date;
  createdBy?: User | null;
  deletedAt?: string | number | Date;
  status?: Status;
  updatedBy?: User | null;
  updatedAt?: string | number | Date;
}

export interface CDLRecordEntity {
  id?: RecordEntityId;
  created_at?: string | number | Date;
  created_by?: User | null;
  deleted_at?: string | number | Date;
  updated_by?: User | null;
  updated_at?: string | number | Date;
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface RecordListMeta {
  limit: number;
  page: number;
  resultCount: number;
  sortBy: string;
  sortOrder: SortOrder;
  totalPages: number;
  totalResults: number;
  search?: string;
  total_results: number;
}

export interface CDLRecordListMeta {
  limit: number;
  page: number;
  result_count: number;
  sort_by: string;
  sort_order: SortOrder;
  total_pages: number;
  total_results: number;
  search?: string;
}

export enum PaginationQueryKeys {
  LIMIT = 'limit',
  PAGE = 'page',
  SORT_BY = 'sortBy',
  SEARCH = 'search',
}

export type PaginationQueryObject = Record<
  PaginationQueryKeys,
  LocationQueryValueRaw | LocationQueryValueRaw[]
>;

export interface Pagination extends RecordListMeta {
  queryParams?: PaginationQueryObject | ComputedRef<PaginationQueryObject>;
}

export interface QueryParams extends Record<string, string | number | Object> {
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: string;
  filter?: Object;
}

// We have snake case attributes for old cdl domain apis
export interface CDLQueryParams extends Record<string, string | number | Object> {
  limit?: number;
  page?: number;
  sort_by?: string;
  sort_order?: string;
  filter?: Object;
  search?: string;
}

export interface PaginationConfig {
  queryParams?: QueryParams;
  metaParams?: {
    [key: string]: string | number | Object;
    resultCount: 0;
    totalPages: 1;
    totalResults: 0;
  };
  watchersOn?: boolean;
}

// We need to make sure all APIs return as results, not items
export interface RecordList<T extends RecordEntity> {
  results: T[];
  meta: RecordListMeta;
}

export interface SingleRecordPayload {
  id: string;
  source_id: string;
}
