import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { GlobalMenuGroupsRoutePathNames, GlobalMenuGroupsRouteNames } from './enums';
import { useMenuPermissionsStore } from '@centric-os/stores';
import { SitesRouteNames } from '@centric-os/sites';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(GlobalMenuGroupsRoutePathNames.GLOBAL_MENU_GROUPS),
  name: GlobalMenuGroupsRouteNames.BRANDS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: routes,
  beforeEnter: () => {
    const menuPermissionsStore = useMenuPermissionsStore();

    if (menuPermissionsStore.canAccessBrandsSection) {
      return true;
    }

    // Change to HOME when we have dashboard
    return { name: SitesRouteNames.SITES_LIST };
  },
};
