import { ref, computed } from 'vue';

export default (isDisabled = false) => {
  const disabled = ref(isDisabled);
  const classes = computed(() => ({
    // 'p-disabled': disabled.value,
  }));

  return { disabled, classes };
};
