import type {
  DataTableCellEditCompleteEvent,
  DataTableCellEditInitEvent,
} from 'primevue/datatable';

import { get, set } from 'lodash';
import { nextTick, ref, watch, type Ref } from 'vue';
const isEditing = ref(false);

export default () => {
  const oldValue: Ref<Record<string, any>> = ref({});

  const handleEditComplete = async (event: DataTableCellEditCompleteEvent, updateCallback: any) => {
    let { data, newValue, field } = event;

    const noUpdateNeeded = newValue === undefined || get(data, field) === newValue;

    if (!isEditing.value || noUpdateNeeded) {
      return;
    }

    oldValue.value = { ...oldValue, [data.id]: get(data, field) };

    data[field] = newValue;
    try {
      await updateCallback(data);
    } catch (e) {
      data[field] = oldValue.value[data.id];
    } finally {
      delete oldValue.value[data.id];
    }
  };

  const handleEditStart = async (event: DataTableCellEditInitEvent) => {
    await nextTick();
    if (!isEditing.value) {
      return;
    }
    let { data, field } = event;
    oldValue.value = { ...oldValue.value, [data.id]: get(data, field) };
  };

  const handleEditEnd = async (event: DataTableCellEditCompleteEvent, updateCallback: any) => {
    let { data, field } = event;
    const noUpdateNeeded = get(data, field) === oldValue.value?.[data.id];
    if (!isEditing.value || noUpdateNeeded) {
      return;
    }
    try {
      // In the case of nested values coming in through the field like modifier.item.price
      // we want to reference only up until the last point for our API call
      const lastIndex = field.lastIndexOf('.');
      const payload = field.substring(0, lastIndex)
        ? get(data, field.substring(0, lastIndex))
        : data;

      await updateCallback(payload);
    } catch (e) {
      set(data, field, oldValue.value?.[data.id]);
    } finally {
      delete oldValue.value[data.id];
    }
  };
  return {
    handleEditComplete,
    handleEditStart,
    handleEditEnd,
    getOldValue: () => oldValue,
    oldValue,
    isEditing,
  };
};
