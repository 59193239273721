import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { FP_PromoRoutePathNames, FP_PromoRouteNames } from './enums';

const App = () => import('./App.vue');

export * from './enums';
export const route: RouteRecordRaw = {
  path: buildRootURL(FP_PromoRoutePathNames.FP_PROMOS),
  name: FP_PromoRouteNames.FP_PROMOS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
};
export { default as fpPromo } from './App.vue';
