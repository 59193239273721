import type { ApiError } from '@centric-os/types';

export const getApiError = (error: any) => {
  const apiError: ApiError = { message: 'API Error', name: 'API Error' };
  if (error.response) {
    if ('code' in error.response.data) {
      const { error: err, code } = error.response.data;
      if (typeof err === 'string') {
        apiError.message = err;
      } else {
        apiError.fields = err;
      }
      apiError.status = code;
    } else {
      const { message, status } = error.response.data;
      if (typeof message === 'string') {
        apiError.message = message;
      } else {
        apiError.fields = message;
      }
      apiError.status = status;
    }
  }
  return apiError;
};
