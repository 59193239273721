import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../enums';
import { useAuthStore } from '@centric-os/stores';
import CDLID from '@compassdigital/id';
import { P2Role } from '@centric-os/types';

const Component = () => import('./SitesList.vue');

/**
 * Navigation guard for the Sites List route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route being navigated away from.
 * @param next - The function to resolve the navigation.
 */
const beforeEnter = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const authStore = useAuthStore();

  try {
    const isSiteOperator = authStore.hasRole(P2Role.SITE_OPERATOR);

    if (isSiteOperator) {
      const allowedResourceIds = authStore.getUserAllowedResourceIds as string[];
      const firstResourceId = allowedResourceIds[0];
      const shouldRedirectToFirstSite =
        allowedResourceIds.length === 1 && CDLID.contains(firstResourceId, { type: 'group' });

      if (shouldRedirectToFirstSite) {
        return next({
          name: SitesRouteNames.SITE_DETAILS,
          params: { siteId: firstResourceId },
          replace: true,
        });
      }
    }

    next();
  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    next(false); // Cancel navigation
  }
};

export default {
  path: buildChildURL(SitesRoutePathNames.LIST),
  name: SitesRouteNames.SITES_LIST,
  component: Component,
  beforeEnter,
} as RouteRecordRaw;
