<template>
  <InputText v-bind="$attrs" v-model="value" ref="input">
    <template v-if="label" #label>{{ label }}</template>
    <template #startAdorment><i class="material-symbols-rounded text-2xl">search</i></template>
    <template #endAdorment>
      <i class="material-symbols-rounded material-spin text-xl" v-if="loading">replay</i>
      <i
        v-else-if="hasValue && showClearFilter"
        class="material-symbols-rounded text-xl cursor-pointer"
        @click="onClearClick($event)"
        >cancel</i
      >
      <!-- Workaround to avoid a clickable area issue -->
      <i v-else></i>
    </template>
    <!-- <template v-if="helperText" #helperText>{{ helperText }}</template> -->
  </InputText>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { InputText } from '../input-text';

const emit = defineEmits(['change']);

const props = defineProps({
  label: String,
  helperText: String,
  loading: Boolean,
  showClearFilter: {
    type: Boolean,
    default: true,
  },
});

const value = defineModel({ type: String });

const input = ref<typeof InputText>();

const hasValue = computed(() => {
  return input?.value?.value ? true : false;
});

const onClearClick = (event: any) => {
  value.value = null;
  emit('change', { originalEvent: event, value: null });
};

defineExpose({ props });
</script>

<script lang="ts">
export default { name: 'InputTextSearch', inheritAttrs: false };
</script>
