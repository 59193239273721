import { ref, computed } from 'vue';

export default (isRequired = false) => {
  const required = ref(isRequired);
  const classes = computed(() => ({
    'c-textarea-required': required.value,
  }));

  return { required, classes };
};
