// Should take into consideration that width does not include padding, border, or margin. Reduce -20px from width to account for padding.
export enum ColumnStyleWidth {
  TEXT_COLUMN = '180px',
  DOUBLE_WIDTH_TEXT_COLUMN = '360px',
  NUMBER_COLUMN = '80px',
  PRICE_COLUMN = '108px',
  FIXED_CONTENT_COLUMN = '108px',
  IMAGE_COLUMN = '108px',
  ICON_COLUMN = '44px',
}
