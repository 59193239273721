<template>
  <div class="c-toolbar flex align-items-center" role="toolbar">
    <div class="w-4 flex">
      <slot name="left"></slot>
    </div>
    <div class="justify-content-center w-3 flex">
      <slot name="center"></slot>
    </div>
    <div class="justify-content-end w-5 flex">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Toolbar',
});
</script>
