<template>
  <div class="c-sidebar-form-action-container">
    <Button
      @click="emit('click', $event)"
      :disabled="disabled"
      :label="label"
      iconPos="top"
      :class="btnClass"
      v-bind="$attrs"
    >
      <template #icon>
        <ProgressSpinner
          class="c-progress-spinner-xs"
          v-if="inProgress"
          strokeWidth="8"
          animationDuration=".5s"
        />
        <i v-else class="material-symbols-rounded text-xl"> {{ icon }} </i>
      </template>
    </Button>
    <Skeleton v-if="loading" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Button } from './../../../../../../../core/buttons';
import { Skeleton, ProgressSpinner } from './../../../../../../../core/misc';

const props = defineProps({
  label: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  inProgress: {
    type: Boolean,
  },
  icon: {
    type: String,
    default: '',
  },
  shrinkToFit: {
    type: Boolean,
  },
  state: {
    type: [String, Array],
    default: '',
  },
});

const btnClass = computed(() => {
  const classes = [props.state] as string[];
  if (props.shrinkToFit) {
    classes.push('px-0');
  }
  return classes;
});

const emit = defineEmits(['click']);
</script>
