import { defineStore, type StateTree } from 'pinia';

interface State extends StateTree {
  headerHeight: number;
}

export const useContentPageFormStore = defineStore('contentPageForm', {
  state: (): State => ({
    headerHeight: 0,
  }),
  getters: {
    height: (state) => `calc(100vh - ${state.headerHeight}px)`,
  },
});
