<template>
  <InputTextSearch
    :placeholder="t(`tableFilter.search`, 'Search')"
    @keyup.enter="setSearchQueryParam($event.target.value)"
    @update:modelValue="reset"
    :loading="loading"
    v-model="value"
  />
</template>

<script setup lang="ts">
import { useStorePagination } from '@centric-os/stores';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { InputTextSearch } from './../../../';

const props = defineProps({
  ...InputTextSearch.props,
  store: { type: Object, required: true },
  updateQuery: { type: Boolean, default: true },
});

const { t } = useI18n();
const { search } = useStorePagination(props.store, {
  updateQuery: props.updateQuery,
});

const setSearchQueryParam = (value: string) => {
  search.value = value;
};

const value = ref('');

const reset = (value: string) => {
  if (!value) {
    search.value = '';
  }
};

onMounted(() => {
  value.value = search.value;
});
</script>
