export enum SitesRoutePathNames {
  SITES = 'sites',
  LIST = '',
  SITE = ':siteId',
  STATION_DETAILS = 'station',
  STATION = ':stationId',
  MENU_HOURS = 'menu-hours',
  PICKUP_DETAILS = 'pickup-details',
  DROPOFF_DETAILS = 'dropoff-details',
  SALES = 'sales-channel',
  DEVICE_MAPPING = 'device-mapping',
  REPORTING = 'reporting',
  KDS = 'kds',
}
