<template>
  <Tag :class="classes">
    {{ t(`${app?.charAt(0).toUpperCase() + app?.slice(1).toLowerCase()}`) }}
  </Tag>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  app: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();

const classes = computed(() => ({
  'c-tag-neutral': true,
}));
</script>
