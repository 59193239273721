import { ref, inject } from 'vue';
import { GlobalEmitterKey } from './../../../../emitter';

export default () => {
  const id = ref('header-bar');
  const emitter = inject(GlobalEmitterKey);

  const toggleHeader = (show: boolean) => {
    emitter.emit('toggleHeader', show);
  };

  return {
    id,
    toggleHeader,
  };
};
