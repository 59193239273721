import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { AP3UserManagementRouteNames, AP3UserManagementRoutePathNames } from '../../enums';

const Component = () => import('./List.vue');

export default {
  path: buildChildURL(AP3UserManagementRoutePathNames.LIST),
  name: AP3UserManagementRouteNames.AP3_USERS_LIST,
  component: Component,
} as RouteRecordRaw;
