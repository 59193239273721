export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonStateClass {
  LINK = 'p-button-link',
  OUTLINE = 'p-button-outlined',
  ROUNDED = 'p-button-rounded',
  TERTIARY = 'p-button-text',
  TEXT = 'p-button-text',
}

export enum ButtonSeverityClass {
  DANGER = 'p-button-danger',
  PLAIN = 'p-button-plain',
  SECONDARY = 'p-button-secondary',
}

export enum ButtonSizeClass {
  SMALL = 'p-button-sm',
  LARGE = 'p-button-lg',
}
