import { defineComponent, h, computed, type PropType } from 'vue';
import { DateTime } from 'luxon';

type DateValue = number | string | Date;

export const DateCell = defineComponent({
  name: 'DateCell',
  props: {
    date: {
      type: [Number, String, Date] as PropType<DateValue>,
      required: true,
    },
  },
  setup(props, { attrs }) {
    // const { d } = useI18n();
    const date = computed(() => {
      return props.date
        ? DateTime.fromJSDate(new Date(props.date as DateValue)).toFormat('MMM dd, yyyy, hh:mm')
        : '';
    });

    return () =>
      h(
        'span',
        {
          class: ['white-space-nowrap', { ...(attrs.class as any) }],
          ...attrs,
        },
        date.value,
      );
  },
});
