import { type ComponentOptionsMixin, defineComponent, type PropType } from 'vue';
import PVTreeTable from 'primevue/treetable';

export default defineComponent({
  extends: PVTreeTable as unknown as ComponentOptionsMixin,
  props: {
    ...(PVTreeTable as unknown as ComponentOptionsMixin).props,
    responsiveLayout: {
      type: String,
      default: 'scroll',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowsPerPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [25, 50, 100],
    },
    rows: {
      type: Number,
      default: 25,
    },
    rowHover: {
      type: Boolean,
      default: true,
    },
    pageLinkSize: {
      type: Number,
      default: 5,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    paginatorTemplate: {
      type: String,
      default:
        'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
    },
    currentPageReportTemplate: {
      type: String,
      default: '{first} - {last} of {totalRecords}',
    },
    value: {
      type: Array as PropType<any[]>,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    // scrollDirection: {
    //   type: String,
    //   default: 'both',
    // },
    scrollHeight: {
      type: String,
      default: 'flex',
    },
  },
});
