import { inject } from 'vue';
import { formSubmitOnEnterSymbol } from '@centric-os/types';

export default () => {
  const validateForm: () => void = inject(formSubmitOnEnterSymbol, undefined);

  const handleEnter = (event: KeyboardEvent) => {
    if (!validateForm || event.shiftKey) return;
    validateForm();
  };
  return { handleEnter };
};
