import { type ComponentOptionsMixin, defineComponent, type PropType, ref, onUpdated } from 'vue';
// Using source code fom pv 3.18.1
import PVDataTable from 'primevue/datatable';

export default defineComponent({
  extends: PVDataTable as unknown as ComponentOptionsMixin,
  props: {
    ...(PVDataTable as unknown as ComponentOptionsMixin).props,
    responsiveLayout: {
      type: String,
      default: 'scroll',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowsPerPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [25, 50, 100],
    },
    rows: {
      type: Number,
      default: 25,
    },
    rowHover: {
      type: Boolean,
      default: true,
    },
    pageLinkSize: {
      type: Number,
      default: 5,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    paginatorTemplate: {
      type: String,
      default:
        'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
    },
    currentPageReportTemplate: {
      type: String,
      default: '{first} - {last} of {totalRecords}',
    },
    value: {
      type: Array as PropType<any[]>,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    scrollDirection: {
      type: String,
      default: 'both',
    },
    scrollHeight: {
      type: String,
      default: 'flex',
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setup() {
    const hoveredElementIndex = ref(undefined);
    const handleHeaderCellsHoverStates = () => {
      const tableHeaders = document.querySelectorAll<HTMLElement>('.p-datatable-thead');
      const topRow = tableHeaders?.[tableHeaders?.length - 1]?.children?.[0] as
        | HTMLElement
        | undefined;
      const bottomRow = tableHeaders?.[tableHeaders?.length - 1]?.children?.[1] as
        | HTMLElement
        | undefined;
      const hoverClasses = ['cell-hover-1', 'cell-hover-2'];

      if (topRow && bottomRow) {
        const handleHoverOn = (index) => {
          const hoverClass = topRow.children?.[index]?.classList.contains('p-highlight')
            ? hoverClasses[1]
            : hoverClasses[0];
          topRow.children?.[index]?.classList.remove(...hoverClasses);
          bottomRow.children?.[index]?.classList.remove(...hoverClasses);
          topRow?.children?.[index]?.classList.add(hoverClass);
          bottomRow?.children?.[index]?.classList.add(hoverClass);
          hoveredElementIndex.value = index;
        };
        const handleHoverOff = (index) => {
          topRow.children?.[index]?.classList.remove(...hoverClasses);
          bottomRow.children?.[index]?.classList.remove(...hoverClasses);
          hoveredElementIndex.value = undefined;
        };
        Array.from(topRow.children).forEach((element, index) => {
          if (element.classList.contains('p-sortable-column')) {
            element.onmouseenter = () => handleHoverOn(index);
            element.onmouseleave = () => handleHoverOff(index);
            bottomRow.children[index].onmouseenter = () => handleHoverOn(index);
            bottomRow.children[index].onmouseleave = () => handleHoverOff(index);
          }
        });
        handleHoverOn(hoveredElementIndex.value);
      }
    };
    onUpdated(() => {
      handleHeaderCellsHoverStates();
    });
  },
});
