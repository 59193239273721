import { defineComponent, type ComponentOptionsMixin } from 'vue';
import { Button } from '../button';

export default defineComponent({
  name: 'IconButton',
  extends: Button as unknown as ComponentOptionsMixin,
  props: {
    ...(Button as unknown as ComponentOptionsMixin).props,
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
  },
});
