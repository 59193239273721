<template>
  <slot
    :value="value"
    :errors="errors"
    :meta="meta"
    :errorMessage="errorMessage"
    :checked="checked"
    :isInvalid="isInvalid"
    v-bind="fieldProps"
  />
</template>

<script setup lang="ts">
import { computed, inject, watch } from 'vue';
import { useField } from 'vee-validate';
import { OnFieldValidatorKey, type OnFieldValidatorFunction } from '@/types';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  validateOnValueUpdate: {
    type: Boolean,
    default: true,
  },
  initialValue: {
    type: [String, Number, Object, Array],
  },
});

const { value, meta, errors, errorMessage, checked, ...fieldProps } = useField(
  props.name as string,
  null,
  {
    validateOnValueUpdate: props.validateOnValueUpdate,
    initialValue: props.initialValue,
  },
);

const isInvalid = computed<boolean>(
  () => !meta.valid && (meta.dirty || meta.touched || hasErrors.value),
);

const hasErrors = computed(() => {
  return Boolean(errors.value?.length);
});

const onFieldValidator: OnFieldValidatorFunction = inject(OnFieldValidatorKey, undefined);

watch(
  hasErrors,
  (newVal) => {
    if (onFieldValidator) {
      onFieldValidator(props.name, newVal);
    }
  },
  { immediate: false },
);
</script>
