<template>
  <div :class="rootClasses">
    <div class="flex align-items-center flex-wrap">
      <label v-if="$slots.label" :for="id" class="flex-grow-1">
        <slot name="label" />
      </label>

      <PVInputSwitch v-bind="$attrs" :id="id" v-model="value" />

      <label v-if="$slots.labelRight" :for="id" class="ml-3 label-right" @click.stop="handleClick">
        <slot name="labelRight" />
      </label>

      <span v-if="$slots.valueText" class="ml-2">
        <slot name="valueText" />
      </span>
    </div>

    <span v-if="$slots.helperText" class="caption mt-2">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script lang="ts">
export default { name: 'InputSwitch', inheritAttrs: false };
</script>

<script setup lang="ts">
import { watchEffect, computed, useAttrs } from 'vue';
import PVInputSwitch from 'primevue/inputswitch';
import { useInputDisabled, useInputRequired } from '../composables';

const attrs = useAttrs();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const value = defineModel<any>();

const { disabled, classes: disabledClasses } = useInputDisabled(attrs.disabled as boolean);

const { required, classes: requiredClasses } = useInputRequired(attrs.required as boolean);

const rootClasses = computed(() => {
  return [
    'field-input-switch',
    disabledClasses.value,
    requiredClasses.value,
    {
      'p-error': Boolean((attrs.class as string)?.includes('p-invalid')),
    },
  ];
});

watchEffect(() => {
  disabled.value = Boolean(attrs.disabled);
  required.value = Boolean(attrs.required);
});

const handleClick = (event: Event) => {
  if (attrs['@click.stop']) {
    (attrs['@click.stop'] as Function)(event);
  }
};
</script>
