<template>
  <Toolbar class="border-bottom-1 h-full">
    <template #left>
      <h6 class="ml-3 md:mr-4 md:ml-4">
        {{ title }}
      </h6>
    </template>
    <template #right>
      <div class="flex mr-3 md:mr-4 flex">
        <Button
          :label="t(`buttons.discard`)"
          class="md:w-5rem lg:w-6rem p-button-text"
          :disabled="discardDisabled"
          @click="showDiscardDialog()"
        />
        <Button
          class="md:w-10rem lg:w-13rem ml-2 md:ml-4"
          :label="t(`buttons.save`)"
          :disabled="saveDisabled"
          @click="$emit('save')"
        />
      </div>
    </template>
  </Toolbar>
</template>

<script setup lang="ts">
import { TagComponentSeverities, useConfirmDialog } from '@centric-os/components';
import { onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  title: String,
  saveDisabled: Boolean,
  discardDisabled: Boolean,
  form: {
    type: Object,
  },
  newRecord: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['save', 'discard']);

const { t } = useI18n();

const accept = () => {
  props.form?.resetForm();
  emit('discard');
};

const reject = () => console.debug('Cancel discard');

const { showConfirmDialog: showDiscardDialog, close } = useConfirmDialog({
  header: t(`discardDialog.header`),
  message: props.newRecord
    ? t(`discardDialog.createMessageAlert`)
    : t(`discardDialog.editMessageAlert`),
  acceptLabel: t(`discardDialog.acceptLabel`),
  rejectLabel: t(`discardDialog.rejectLabel`),
  accept,
  reject,
  severity: TagComponentSeverities.DANGER,
});

onBeforeUnmount(() => {
  close();
});
</script>
