<template>
  <section
    v-if="!noHeader"
    id="main-content-header"
    ref="mainContentHeaderRef"
    class="sticky top-0 z-1"
  >
    <!-- Header loading template -->
    <template v-if="loading">
      <Transition name="fade">
        <section id="loading-record-header" class="c-content-header px-4">
          <Grid>
            <Col1>
              <!-- Breadcrumb skeleton -->
              <Skeleton />
            </Col1>

            <Col1>
              <!-- Breadcrumb skeleton -->
              <Skeleton />
            </Col1>

            <Col1>
              <!-- Breadcrumb skeleton -->
              <Skeleton />
            </Col1>
          </Grid>

          <Grid class="grid-nogutter">
            <Col3 class="pr-3">
              <!-- Title header breadcrumb skeleton -->
              <Skeleton height="2.5rem" />
            </Col3>

            <Col4 class="col-offset-5">
              <Grid>
                <!-- Action button skeleton -->
                <Col4 class="col-offset-4">
                  <Skeleton height="2.5rem" />
                </Col4>

                <Col4>
                  <Skeleton height="2.5rem" />
                </Col4>
              </Grid>
            </Col4>
          </Grid>
        </section>
      </Transition>

      <div class="w-full border-bottom-1 border-300" />
    </template>

    <!-- Header content header template -->
    <template v-else>
      <Transition name="fade">
        <div>
          <div class="c-page-breadcrumb">
            <slot name="breadcrumb" />
          </div>

          <template v-if="$slots.header">
            <section id="custom-record-header" class="c-content-header px-4">
              <slot name="header" />
            </section>
          </template>

          <template v-else>
            <section id="record-header" class="c-content-header">
              <div class="flex justify-content-between flex-wrap">
                <div class="flex flex-column justify-content-start title">
                  <h1 class="font-semibold">{{ title }}</h1>
                </div>

                <div class="flex align-items-center justify-content-center actions">
                  <div class="flex align-items-center">
                    <template v-if="$slots.actions">
                      <slot name="actions" />
                    </template>
                  </div>

                  <div class="flex align-items-center mr-3">
                    <Button
                      outlined
                      severity="secondary"
                      :label="cancelLabel"
                      @click="emit('cancel')"
                      :disabled="actionDisabled"
                    />
                  </div>

                  <div class="flex align-items-center">
                    <Button :label="actionLabel" @click="emit('save')" :disabled="actionDisabled" />
                  </div>
                </div>
              </div>
            </section>
          </template>

          <div class="w-full border-bottom-1 border-300" />
        </div>
      </Transition>
    </template>
  </section>
</template>

<script setup lang="ts">
import { Button } from '../../../../../core/buttons/button';
import { Skeleton } from '../../../../../core/misc';
import { Grid, Col1, Col3, Col4 } from '../../../../../core/layouts';
import { ref, watch } from 'vue';
import { useElementSize } from '@vueuse/core';
import { useContentPageFormStore } from './../../../../../../../stores';

// Define emits
const emit = defineEmits(['save', 'cancel']);

//  Define props
const props = defineProps({
  title: {
    type: String,
  },
  actionLabel: {
    type: String,
  },
  cancelLabel: {
    type: String,
  },
  actionDisabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  noHeader: {
    type: Boolean,
    default: false,
  },
});

// Import useElementSize from vueuse
const mainContentHeaderRef = ref();
const { height: mainContentHeaderHeight } = useElementSize(mainContentHeaderRef);

// Set form page store
const formPageStore = useContentPageFormStore();

// Watch for changes in mainContentHeaderHeight
watch(
  mainContentHeaderHeight,
  (newVal) => {
    let height = newVal; // Will equate to 0 if props.noHeader is true
    const titleBarHeader: HTMLElement = document.querySelector('#title-bar');
    if (titleBarHeader) {
      height += titleBarHeader?.offsetHeight;
    }
    formPageStore.contentPageHeaderHeight = height;
  },
  { immediate: props.noHeader },
);
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
