<template>
  <router-link :to="to">
    <Button :label="btnLabel" :class="['p-button-link white-space-nowrap', buttonClass]" />
  </router-link>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { ColumnData } from './../types';
import { get } from 'lodash-es';
import type { RouteLocationRaw } from 'vue-router';
import { Button } from './../../../../buttons';

const props = defineProps({
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    required: true,
  },
  columnData: {
    type: Object as PropType<ColumnData>,
  },
  label: String,
  buttonClass: String,
});

const btnLabel = computed<string>(() => {
  let label = '';
  if (props.label) {
    label = props.label as string;
  } else if (props.columnData) {
    const { data, field } = props.columnData as ColumnData;
    label = get(data, field, '') as string;
  }

  return label;
});
</script>
