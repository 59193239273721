import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { OrdersRouteNames, OrdersRoutePathNames } from '../../enums';

const Component = () => import('./OrderList.vue');

export default {
  path: buildChildURL(OrdersRoutePathNames.LIST),
  name: OrdersRouteNames.ORDER_LIST,
  component: Component,
} as RouteRecordRaw;
