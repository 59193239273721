<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVTextarea
      :autoResize="true"
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    />

    <!-- Show char counter if enabled -->
    <template v-if="showCounter">
      <div class="flex justify-content-between">
        <span v-if="$slots.helperText" class="caption">
          <slot name="helperText" />
        </span>

        <span v-else></span>

        <span :id="`textarea-counter-${$attrs?.id}`" class="caption">
          {{ currentChar + '/' + maxChars }}
        </span>
      </div>
    </template>

    <template v-else>
      <span v-if="$slots.helperText" class="caption">
        <slot name="helperText" />
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useAttrs, computed } from 'vue';
import PVTextarea from 'primevue/textarea';
import { useTextareaState } from '../composables';

// Define props, attrs, and model
const props = defineProps({
  placeholder: String,
  maxChars: Number,
});

const attrs = useAttrs();

const value = defineModel({
  type: String,
});

const { focused, hovered, classes: rootClasses } = useTextareaState(attrs);

// Compute counter visibility
const showCounter = computed<boolean>(() => {
  return props?.maxChars && props.maxChars > 0;
});

// Compute char counter
const currentChar = computed<number>(() => {
  return value.value?.length ? value.value.length : 0;
});

defineExpose({
  value,
});
</script>

<script lang="ts">
export default { name: 'Textarea', inheritAttrs: false };
</script>
