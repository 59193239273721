<template>
  <Tag :class="classes">
    {{ t(`tags.${status}`) }}
  </Tag>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { Tag } from './../tag';
import { Status } from '@centric-os/types';

const props = defineProps({
  status: {
    type: String as PropType<Status>,
    required: true,
  },
});

const { t } = useI18n();
const classes = computed(() => ({
  'c-tag-neutral': props.status !== Status.ACTIVE,
}));
</script>
