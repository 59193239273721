<template>
  <div class="grid-nogutter flex flex-wrap">
    <div :class="leftColumnClass">
      <Card
        class="main-content-card"
        :class="[
          cardClassesHelper.NO_SHADOWS,
          fullViewport ? cardClassesHelper.FULL_VIEWPORT : '',
          noPaddingLeft ? 'no-padding-left-part' : '',
        ]"
      >
        <template #content>
          <template v-if="loading">
            <div class="grid">
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
            </div>
          </template>

          <template v-else>
            <slot name="leftColumn" />
          </template>
        </template>
      </Card>
    </div>

    <div :class="centerColumnClass">
      <Card
        class="main-content-card"
        :class="[
          cardClassesHelper.NO_SHADOWS,
          cardClassesHelper.LEFT_BORDER,
          fullViewport ? cardClassesHelper.FULL_VIEWPORT : '',
        ]"
      >
        <template #content>
          <template v-if="loading">
            <div class="grid">
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
            </div>
          </template>

          <template v-else>
            <slot name="middleColumn" />
          </template>
        </template>
      </Card>
    </div>

    <div :class="rightColumnClass">
      <Card
        id="main-content-card"
        class="main-content-card"
        :class="[
          cardClassesHelper.NO_SHADOWS,
          cardClassesHelper.LEFT_BORDER,
          fullViewport ? cardClassesHelper.FULL_VIEWPORT : '',
          noPaddingRight ? 'no-padding-right-part' : '',
        ]"
      >
        <template #content>
          <template v-if="loading">
            <div class="grid">
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
              <div class="col-12">
                <Skeleton width="70%" class="mb-2" />
                <Skeleton />
              </div>
            </div>
          </template>

          <template v-else>
            <slot name="rightColumn" />
          </template>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Card, CardHelperClasses } from '../../containers/cards';
import { Skeleton } from '../../misc';
import { useContentPageStore } from '@centric-os/stores';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fullViewport: {
    type: Boolean,
    default: false,
  },
  noPaddingLeft: {
    type: Boolean,
    default: false,
  },
  noPaddingRight: {
    type: Boolean,
    default: false,
  },
  leftColumnClass: {
    type: String,
    default: 'col-12 md:col-3',
  },
  centerColumnClass: {
    type: String,
    default: 'col-12 md:col-5',
  },
  rightColumnClass: {
    type: String,
    default: 'col-12 md:col-4',
  },
});

const cardClassesHelper = CardHelperClasses;

const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);
</script>

<style lang="scss" scoped>
:deep(.p-card) {
  &.card-full-viewport {
    height: v-bind('contentPageLayoutHeight');
    max-height: v-bind('contentPageLayoutHeight');

    .p-card-body {
      max-height: v-bind('contentPageLayoutHeight');
      overflow-y: auto;
    }
  }

  &.no-padding-left-part {
    .p-card-body {
      padding: 0;
    }
  }

  &.no-padding-right-part {
    .p-card-body {
      padding: 0;
    }
  }
}
</style>
