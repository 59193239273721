import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../../../enums';

const Component = () => import('./BrandModifiers.vue');

export default {
  path: buildChildURL(GlobalMenuGroupsRoutePathNames.MODIFIERS),
  name: GlobalMenuGroupsRouteNames.BRAND_MODIFIERS,
  component: Component,
} as RouteRecordRaw;
