import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { OrdersRouteNames, OrdersRoutePathNames } from '../../../../enums';

const Component = () => import('./OrderNotFound.vue');

export default {
  path: buildChildURL(OrdersRoutePathNames.NOT_FOUND),
  name: OrdersRouteNames.ORDER_NOT_FOUND,
  component: Component,
} as RouteRecordRaw;
