export interface InputAttrs {
  disabled?: boolean;
  required?: boolean;
  class?: string;
  customClass?: string;
}

export interface InputProps {
  useSecondaryStyles?: boolean;
}

export const formSubmitOnEnterSymbol = Symbol('formSubmitOnEnter');

export const formInitialValuesSymbol = Symbol('formInitialValues');

export const formIdSymbol = Symbol('formId');
