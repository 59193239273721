import { defineStore } from 'pinia';

interface State {
  formSidebar: string;
  isFormDirty: boolean;
}

export const useFormSidebarStore = defineStore('formSidebar', {
  state: (): State => ({
    formSidebar: null,
    isFormDirty: false,
  }),
});
