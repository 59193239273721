import { useAuthStore, useSiteStore } from '@centric-os/stores';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { storeToRefs } from 'pinia';

// Module augmentation to extend the Window interface
declare global {
  interface Window {
    Appcues?: {
      identify: (userId: string, properties: Record<string, any>) => void;
    };
    AppcuesSettings?: {
      enableURLDetection: boolean;
    };
  }
}

/**
 * Identifies the user and sets user data in Appcues for a personalized experience.
 *
 * This function fetches the necessary user and site information from the relevant stores,
 * and then uses the Appcues JavaScript API to identify the user with these details.
 *
 * @returns {Promise<void>} A promise that resolves when the identification is complete.
 *
 * @example
 * import { appcuesIdentify } from './path/to/this/file';
 *
 * (async () => {
 *   await appcuesIdentify();
 * })();
 */
const appcuesIdentify = async (): Promise<void> => {
  const siteStore = useSiteStore();
  const authStore = useAuthStore();
  const { userId, cdlUser } = storeToRefs(authStore);
  const { site } = storeToRefs(siteStore);

  if (!window.Appcues) {
    console.warn('Appcues is not available on the window object.');
    return;
  }

  try {
    const email = cdlUser.value?.email || '';
    const name = `${cdlUser.value?.name?.first || ''} ${cdlUser.value?.name?.last || ''}`.trim();
    const role = typeof authStore.getRole === 'function' ? authStore.getRole() : authStore.getRole;

    window.Appcues.identify(userId.value, {
      email,
      name,
      site: site.value,
      role,
    });
  } catch (error) {
    console.error('Failed to identify user in Appcues:', error);
  }
};
const APPCUE_PARAM = 'appcue';

const extractAppcuesId = (value: string): string => {
  const [, appcuesValue] = value.split('?');
  return appcuesValue.split('=')[1];
};

const appcuesGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const query = { ...to.query };
  let appcuesId = '';

  const misplacedAppcuesParam = Object.entries(query).find(
    ([key, value]) =>
      key !== APPCUE_PARAM && typeof value === 'string' && value.includes('?appcue'),
  );

  if (misplacedAppcuesParam) {
    const [key, value] = misplacedAppcuesParam;
    appcuesId = extractAppcuesId(value as string);
    const [paramValue] = (value as string).split('?');
    query[key] = paramValue;
    query[APPCUE_PARAM] = appcuesId;
    next({ path: to.path, query, replace: true });
  } else if (to.query[APPCUE_PARAM]) {
    next();
  } else {
    next();
  }
};

export { appcuesIdentify, appcuesGuard };
