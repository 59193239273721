<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVPassword
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      :feedback="false"
    />

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue';
import PVPassword from 'primevue/password';
import { useInputState } from '..';

const attrs = useAttrs();

const props = defineProps({
  placeholder: String,
});

const value = defineModel<any>();

const { focused, hovered, classes: rootClasses } = useInputState(attrs);
</script>

<script lang="ts">
export default { name: 'Password', inheritAttrs: false };
</script>
