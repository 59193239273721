import type { RouteRecordRaw } from 'vue-router';

import cpgItemsListRoute from './cpg-items-list';
import librariesList from './libraries-list';
import verifiedItemsRoute from './cpg-items-list/components/cpg-verified-items';
import CpgPendingItems from './cpg-items-list/components/cpg-pending-items';

export default [
  cpgItemsListRoute,
  librariesList,
  verifiedItemsRoute,
  CpgPendingItems,
] as RouteRecordRaw[];
