import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../../enums';

const Component = () => import('./Reporting.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.REPORTING),
  name: SitesRouteNames.SITE_REPORTING,
  component: Component,
} as RouteRecordRaw;
