import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../../../enums';

const Component = () => import('./LocalBrandJwoAudit.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.JWO_AUDIT),
  name: LocalMenuGroupRouteNames.BRAND_JWO_AUDIT,
  component: Component,
} as RouteRecordRaw;
