import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { LoginRouteNames } from '@centric-os/login';
import { RootRouteNames } from '../../../enums';
import { useAuthStore } from '@centric-os/stores';
import { heap } from '@centric-os/helpers';
import { SitesRouteNames } from '@centric-os/sites/src/enums';
import { storeToRefs } from 'pinia';

/**
 * Navigation guard to handle authentication and route redirection.
 * @param to - The target route.
 * @param from - The current route.
 * @param next - The function to resolve the navigation.
 */
const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> => {
  const store = useAuthStore();
  const token = await store.getValidAccessToken();
  if (token) {
    handleValidToken(to, next);
  } else {
    const omsCode = to.query.code as string | undefined;
    if (omsCode) {
      await handleOMSCode(to, next, omsCode);
    } else {
      handleLogout(to, next);
    }
  }
};

/**
 * Handle navigation when a valid token is present.
 * @param to - The target route.
 * @param next - The function to resolve the navigation.
 */
const handleValidToken = (to: RouteLocationNormalized, next: NavigationGuardNext): void => {
  if (to.name === LoginRouteNames.LOGIN) {
    next({ name: SitesRouteNames.SITES_LIST, replace: true });
  } else {
    next();
  }
};

/**
 * Handle OMS code callback and navigation.
 * @param to - The target route.
 * @param next - The function to resolve the navigation.
 * @param store - The authentication store.
 * @param omsCode - The OMS code from the query parameters.
 */
const handleOMSCode = async (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  omsCode: string,
): Promise<void> => {
  const authStore = useAuthStore();
  const { cdlUser, usedSSO, ssoRedirectError } = storeToRefs(authStore);

  try {
    ssoRedirectError.value = false;
    await authStore.handlOMSCodeCallback({ code: omsCode, redirectPath: to.path });

    heap().init(cdlUser, usedSSO, authStore.getRole, authStore.hasRole);

    next({
      ...to,
      query: {} as RouteLocationNormalized['query'],
      replace: true,
    });
  } catch (error) {
    console.error('Error handling OMS code', error);
    ssoRedirectError.value = true;
    handleLogout(to, next);
  }
};

/**
 * Handle logout process and navigation redirection.
 * @param to - The target route.
 * @param next - The function to resolve the navigation.
 * @param store - The authentication store.
 */
const handleLogout = (to: RouteLocationNormalized, next: NavigationGuardNext): void => {
  const authStore = useAuthStore();
  authStore.logout();

  if (to.name !== LoginRouteNames.LOGIN) {
    if (to.name !== RootRouteNames.ROOT && to.name !== RootRouteNames.MAIN) {
      authStore.intendedRoute = to;
    }
    next({ name: LoginRouteNames.LOGIN, replace: true });
  } else {
    next();
  }
};

export default authGuard;
