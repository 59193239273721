import { useBreakpoints as useBreakpointsFn } from '@vueuse/core';
import { type InjectionKey, inject, type App } from 'vue';

interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

type UseBreakpointsReturn = ReturnType<typeof useBreakpointsFn>;

const BreakpointInjectionKey: InjectionKey<Breakpoints> = Symbol('breakpoints');

export const createBreakpoints = (breakpoints: Breakpoints) => {
  return (app: App) => {
    app.provide(BreakpointInjectionKey, breakpoints);
  };
};

export const useBreakpoints = (): UseBreakpointsReturn => {
  const breakpoints = inject(BreakpointInjectionKey as symbol);

  if (!breakpoints)
    throw new Error('Need to use the plugin createBreakpoints on your Vue application');

  return useBreakpointsFn(breakpoints) as UseBreakpointsReturn;
};
