<template>
  <section :class="classes">
    <!-- Loading template -->
    <template v-if="loading">
      <Card v-for="n in 3" :key="n">
        <template #content>
          <Grid>
            <Col12>
              <Skeleton />
            </Col12>

            <Col12>
              <Skeleton />
            </Col12>

            <Col12>
              <Skeleton />
            </Col12>
          </Grid>
        </template>
      </Card>
    </template>

    <!-- Main content template -->
    <template v-else>
      <Transition name="fade">
        <div class="c-page-content">
          <slot name="content" />
        </div>
      </Transition>
    </template>
  </section>
</template>

<script setup lang="ts">
import { useBreakpoints } from './../../../../../../../helpers';
import { Card, Col12, Grid, Skeleton } from '../../../../../core';
import { computed } from 'vue';

//  Define props
defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

// Set up breakpoints
const breakpoints = useBreakpoints();

// Compute classes
const classes = computed<string[]>(() => {
  const rootClasses: string[] = ['c-page-container', 'z-0', 'py-4'];

  // Add padding classes based on breakpoints (custom classes don't work on primeflex breakpoint utils)
  if (breakpoints.isGreater('xl')) {
    rootClasses.push('px-10');
  } else if (breakpoints.isGreater('lg')) {
    rootClasses.push('px-8');
  } else if (breakpoints.isGreater('md')) {
    rootClasses.push('px-6');
  }
  if (breakpoints.isGreater('sm')) {
    rootClasses.push('px-4');
  } else {
    rootClasses.push('px-0');
  }

  return rootClasses;
});
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
