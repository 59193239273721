<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <Chips
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="chips"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </Chips>
    </template>
  </FieldValidator>
</template>

<script lang="ts">
export default { name: 'ChipsField', inheritAttrs: false };
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { Chips } from './../../../core/controls/chips';
import { FieldValidator } from './../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
});

const chips = ref<InstanceType<typeof Chips>>();

defineExpose({ chips });
</script>
