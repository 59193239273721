<template>
  <div :class="rootClasses">
    <div v-if="$slots?.label || $slots?.helperText" class="mb-2">
      <label v-if="$slots.label">
        <slot name="label" />
      </label>
      <span v-if="$slots.helperText">
        <slot name="helperText" />
      </span>
    </div>
    <template v-if="loading">
      <Skeleton v-for="skeleton in 4" :key="skeleton" height="51px" class="mb-2" />
    </template>
    <draggable
      v-else
      id="reorder-list"
      v-model="value"
      @start="drag = true"
      @end="onDragEnd()"
      :item-key="itemKey"
      :disabled="disabled || !sortable"
    >
      >
      <template #item="{ element }">
        <div
          class="drag-item flex justify-content-start align-items-center mb-2 px-3 py-1 border-round-lg"
          :class="{
            'drag-item-highlight': isSelected(element),
            'drag-item-disabled': disabled,
            'drag-item-removeable': !removeable,
          }"
          @pointerdown="select(element)"
          @pointerup="selected = null"
        >
          <i
            class="drag-button material-symbols-rounded p-datatable-reorderablerow-handle mr-3"
            v-if="sortable"
            >drag_indicator</i
          >
          <span class="text-sm">{{ getOptionLabel(element) }}</span>
          <div class="ml-auto">
            <IconButton class="remove-button c-icon-button" @click="removeItem(element)">
              <template #icon>
                <i class="material-symbols-rounded"> remove_circle_outline </i>
              </template>
            </IconButton>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>
<script lang="ts" setup>
import { ref, useAttrs, type PropType } from 'vue';
import draggable from 'vuedraggable';
import { IconButton } from '../../buttons/icon-button';
import { Skeleton } from '../../misc/skeleton';
import { useInputState } from '../../controls';
import { isEqual } from 'lodash';
const props = defineProps({
  loading: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  itemKey: {
    type: String,
    default: 'id',
  },
  optionLabel: {
    type: String,
    default: 'name',
  },
  removeable: {
    type: Boolean,
    default: false,
  },
  sortable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['removeItem', 'select', 'onDragEnd']);

const value = defineModel({ type: Array || (undefined as PropType<any[]>), default: () => [] });

const drag = ref(false);

const selected = ref();
const select = (element: any) => {
  if (!props.sortable) {
    return;
  }
  selected.value = element;
  emit('select');
};

const isSelected = (element: any): boolean => {
  if (selected?.value && isEqual(element, selected.value)) {
    return true;
  }
  return false;
};

const onDragEnd = async () => {
  drag.value = false;
  selected.value = null;
  emit('onDragEnd');
};
const removeItem = (element: any) => {
  value.value = value.value?.filter((item) => {
    return item?.[props.itemKey] !== element?.[props.itemKey];
  });
  emit('removeItem', element);
};

function getOptionLabel(elementObject) {
  const segments = props.optionLabel.split('.');
  for (const segment of segments) {
    if (!(segment in elementObject)) return undefined;
    elementObject = elementObject[segment];
  }
  return elementObject;
}

const attrs = useAttrs();

const { classes: rootClasses } = useInputState(attrs);
</script>
<style scoped lang="scss">
@import '@centric-os/styles';

.drag-item {
  @extend .cursor-pointer;
  @extend .w-full;
  border: solid 1px $shade400;
  color: #000000;

  &.drag-item-highlight {
    @extend .font-semibold;

    color: $highlightTextColor;
    background: #eff6ff;
  }
  &.drag-item-disabled {
    pointer-events: none;
  }
  &.drag-item-removeable {
    button {
      opacity: 0;
      pointer-events: none;
    }
  }
  .drag-button {
    color: $shade600;
  }
  :focus {
    background: #c5dbff;
  }
  .remove-button {
    color: #6c757d;
  }
}
</style>
