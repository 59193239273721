import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../enums';

const Component = () => import('./StationSchedule.vue');

export default {
  path: buildChildURL(
    SitesRoutePathNames.SITE,
    SitesRoutePathNames.STATION_DETAILS,
    SitesRoutePathNames.STATION,
  ),
  name: SitesRouteNames.SITE_STATION_SCHEDULE,
  component: Component,
} as RouteRecordRaw;
