<template>
  <div>
    <slot name="content" />
  </div>
</template>

<script lang="ts" setup>
import { provide } from 'vue';
import { type SidebarFormValidationEmitPayload, OnFieldValidatorKey } from '@centric-os/types';
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onValidate']);
const onFieldValidator = async (fieldName: string, hasError: boolean) => {
  const payload: SidebarFormValidationEmitPayload = { fieldName, label: props.label, hasError };
  emit('onValidate', payload);
};
provide(OnFieldValidatorKey, onFieldValidator);
</script>
