<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <slot v-if="$slots.startAdorment" name="startAdorment" />

    <PVAutoComplete
      v-model="value"
      :placeholder="placeholder"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      v-bind="$attrs"
    >
      <template v-if="$slots.option" #option="slotProps">
        <slot name="option" v-bind="slotProps"></slot>
      </template>
    </PVAutoComplete>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { useAttrs, useSlots, computed } from 'vue';
import PVAutoComplete from 'primevue/autocomplete';
import { useInputState } from '../composables';

const value = defineModel({
  required: true,
});

const props = defineProps({
  placeholder: String,
});

const attrs = useAttrs();
const slots = useSlots();

const { focused, hovered, classes } = useInputState(attrs);

const rootClasses = computed(() => [
  ...classes.value,
  'c-autocomplete',
  {
    'p-input-icon-left': slots.startAdorment,
  },
]);

defineExpose({ value });
</script>

<script lang="ts">
export default {
  name: 'AutoComplete',
  inheritAttrs: false,
};
</script>
