import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { LoginRouteNames, LoginRoutePathNames } from '../enums';

const App = () => import('./App.vue');

export const route: RouteRecordRaw = {
  path: buildRootURL(LoginRoutePathNames.LOGIN),
  name: LoginRouteNames.LOGIN,
  component: App,
};
