import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../../../enums';

const Component = () => import('./LocalBrandViews.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.MENUS, LocalMenuGroupRoutePathNames.MENUS_ID),
  name: LocalMenuGroupRouteNames.BRAND_MENUS,
  component: Component,
} as RouteRecordRaw;
