import type { RouteRecordRaw } from 'vue-router';

import salesSummaryRoute from './sales-summary';
import salesDistributionRoute from './sales-distribution';
import salesMixRoute from './sales-mix';
import refundReportsRoute from './refunds';

export default [
  salesSummaryRoute,
  salesDistributionRoute,
  salesMixRoute,
  refundReportsRoute,
] as RouteRecordRaw[];
