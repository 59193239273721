import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LibrariesRouteNames, LibrariesRoutePathNames } from '../../../../enums';

const Component = () => import('./CpgPendingItems.vue');

export default {
  path: buildChildURL(
    LibrariesRoutePathNames.CPG_ITEMS_LIST,
    LibrariesRoutePathNames.PENDING_ITEMS,
  ),
  name: LibrariesRouteNames.PENDING_ITEMS,
  component: Component,
} as RouteRecordRaw;
