<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, handleBlur, errorMessage }">
      <Calendar
        :modelValue="value"
        v-bind="$attrs"
        @update:modelValue="handleChange"
        @blur="handleBlur"
        :class="{ 'p-invalid': errorMessage }"
        ref="calendar"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="errorMessage" #helperText>{{ errorMessage }}</template>

        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
          <slot v-if="slot" :name="slot" v-bind="scope" />
        </template>
      </Calendar>
    </template>
  </FieldValidator>
</template>
<script lang="ts">
export default { name: 'CalendarField', inheritAttrs: false };
</script>

<script setup lang="ts">
import { Calendar } from './../../../core/controls';
import { FieldValidator } from './../field-validator';
import { ref } from 'vue';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
});

const calendar = ref<InstanceType<typeof Calendar>>();

defineExpose({ calendar });
</script>
