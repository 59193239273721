<template>
  <div :class="['c-sticky-threshold', thresholdClass]" ref="stickyThresholdElmRef"></div>
  <div :class="classes" v-bind="$attrs">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  thresholdClass: String,
  rootMargin: String,
});

const stickyThresholdElmRef = ref(null);
const isStuck = ref(false);

const classes = computed(() => ['c-sticky', { 'is-stuck': isStuck.value }]);

useIntersectionObserver(
  stickyThresholdElmRef,
  ([{ isIntersecting }]) => {
    isStuck.value = !isIntersecting;
  },
  { rootMargin: props.rootMargin as string },
);
</script>
