<template>
  <Checkbox :id="getOptionId(option)" class="mr-4" v-model="modelValueProxy" :binary="true" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { isEqual } from 'lodash';

const props = defineProps({
  option: [Object, String],
  modelValue: {
    type: Array,
  },
});

const modelValueProxy = computed({
  get() {
    if (Array.isArray(props.modelValue)) {
      return props?.modelValue?.some((val) => isEqual(val, props.option));
    }
    return isEqual(props.modelValue, props.option);
  },
  set() {
    return;
  },
});

// Get the option label
const getOptionId = (option: any): string => {
  return typeof option === 'object' ? option?.[attrs?.optionLabel] : option;
};
</script>
