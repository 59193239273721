import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './src/App.vue';
import PrimeVue from 'primevue/config';
import { router } from './src/router';
import { createI18n } from '@centric-os/platform/i18n';
import { registerAppComponents } from './src/components';
import { ToastService, DocumentTitleLocaleKey, ConfirmationService } from '@centric-os/components';
import emitter, { GlobalEmitterKey } from './src/emitter';
import enLocale from './src/locale/en';
import '@centric-os/styles';
import stores from './src/store';
import { createBreakpoints } from '@centric-os/helpers';
import JsonViewer from 'vue3-json-viewer';
import Tooltip from 'primevue/tooltip';
import { DomHandler } from 'primevue/utils';

export * from './src/components/header-bar/hooks';
export * from './src/composables';

// import 'material-symbols';

const app = createApp(App);
// disable sentry until further notice
/*
  // init and configure sentry for error reporting
  Sentry.init({
    app,
    dsn: 'https://dbfb70b9ab052b188d789e0783155c09@o1169560.ingest.sentry.io/4506718856806400',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_STAGE,
    trackComponents: true,
  });
*/
app.use(PrimeVue, { ripple: true });
app.use(router);
app.use(
  createI18n({
    messages: { en: enLocale },
  }),
);
app.use(ConfirmationService);
app.use(ToastService);
app.use(stores);
app.use(
  createBreakpoints({
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  }),
);
app.use(JsonViewer);

app.provide(GlobalEmitterKey, emitter);
app.provide(DocumentTitleLocaleKey, {
  wrapperKey: 'documentTitleWrapper',
});

function getTarget(el) {
  return DomHandler.hasClass(el, 'p-inputwrapper') ? DomHandler.findSingle(el, 'input') : el;
}

app.directive('tooltip', {
  mounted(el) {
    const target = getTarget(el);
    target.$_ptooltipZIndex ??= app.config.globalProperties.$primevue.config.zIndex.tooltip;
  },
  ...Tooltip,
});

registerAppComponents(app);

app.mount('#app');
