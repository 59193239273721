<template>
  <div :class="rootClasses">
    <div class="flex align-items-center">
      <PVRadioButton v-bind="$attrs" :inputId="id" v-model="value" />

      <label
        v-if="$slots.default"
        :for="id"
        class="cursor-pointer ml-2"
        :class="{ 'p-disabled': disabled }"
      >
        <slot />
      </label>
    </div>

    <span v-if="$slots.helperText" class="p-invalid p-error mt-2 caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, useAttrs } from 'vue';
import PVRadioButton from 'primevue/radiobutton';
import { useInputDisabled } from '../composables';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const attrs = useAttrs();

const value = defineModel<any>();

const { disabled, classes: disabledClasses } = useInputDisabled(attrs.disabled as boolean);

const rootClasses = computed(() => {
  return ['field-radiobutton', disabledClasses.value, 'flex', 'flex-column', 'align-items-start'];
});

watch(
  () => attrs.disabled,
  () => (disabled.value = attrs.disabled as boolean),
);
</script>

<script lang="ts">
export default { name: 'RadioButton', inheritAttrs: false };
</script>
