import type { Ref } from 'vue';
import { P2Role, type UserWithPermissions } from '@centric-os/types';
import { cdlApi } from '@centric-os/app/src/api';
import { version } from '../../../../package.json';

let instance: HeapAnalytics;

declare global {
  interface Window {
    heap: any;
  }
}

interface TrackEvents {
  aiDescriptionGenerated: ({
    itemLabel,
    menuName,
    brandName,
    LocalMenuGroupName,
    description,
  }: {
    itemLabel: string;
    menuName: string;
    brandName: string;
    LocalMenuGroupName: string;
    description: string;
  }) => void;
  aiImageGenerated: ({
    itemLabel,
    menuName,
    brandName,
    LocalMenuGroupName,
  }: {
    itemLabel: string;
    menuName: string;
    brandName: string;
    LocalMenuGroupName: string;
  }) => void;
  aiImageUsed: ({
    itemLabel,
    menuName,
    brandName,
    LocalMenuGroupName,
  }: {
    itemLabel: string;
    menuName: string;
    brandName: string;
    LocalMenuGroupName: string;
  }) => void;
}

enum EVENTS {
  AI_DESCRIPTION_GENERATED = 'ai_description_generated',
  AI_IMAGE_GENERATED = 'ai_image_generated',
  AI_IMAGE_USED = 'ai_image_used',
}

class HeapAnalytics {
  private cdlUser: UserWithPermissions;
  private usedSSO: boolean;
  private getRole;
  private hasRole;
  public aiImageGenerated = false; // used to track an event when an AI generated image is used for an item

  async init(
    cdlUser: Ref<UserWithPermissions>,
    usedSSO: Ref<boolean>,
    getRole,
    hasRole,
  ): Promise<void> {
    if (!window.heap?.loaded) {
      window.heap?.load(import.meta.env.VITE_HEAP_API_KEY);
    }
    this.cdlUser = cdlUser.value;
    this.usedSSO = usedSSO.value;
    this.getRole = getRole;
    this.hasRole = hasRole;

    const userProperties: Record<string, any> = {
      version,
    };

    if (this.cdlUser) {
      if (this.cdlUser.email) {
        window.heap?.identify(this.cdlUser.email);
      }

      if (this.cdlUser.name?.first && this.cdlUser.name?.last) {
        userProperties.fullName = `${this.cdlUser.name.first} ${this.cdlUser.name.last}`;
      }

      if (this.cdlUser.date?.created) {
        userProperties.creationDate = this.cdlUser.date.created;
      }

      const sites = await this.getSiteNames();
      userProperties.site = sites;
      userProperties.usedSSO = this.usedSSO;
      userProperties.role = this.getRole;
    }

    window.heap?.addUserProperties(userProperties);
  }

  private async getSiteNames(): Promise<string> {
    const promises: Promise<any>[] = [];
    const userSiteIds = this.getUserSiteIds();
    if (userSiteIds === 'All') return userSiteIds;
    userSiteIds.forEach((siteId) => {
      promises.push(cdlApi.get(`/location/group/${siteId}?include_estimated_wait_time=false`));
    });
    const sitesData = await Promise.all(promises);
    const siteNames = sitesData.map(({ data: userSite }) => userSite.name || 'None').join(',');
    return siteNames;
  }

  private getUserSiteIds(): string[] | 'All' {
    if (this.isAdmin || this.hasRole(P2Role.SYS_ADMIN)) return 'All';
    return this.getPermissions
      .filter((scope) => {
        return (
          scope.includes(P2Role.SITE_OPERATOR) ||
          scope.includes(P2Role.IM_USER) ||
          scope.includes(P2Role.MENU_USER) ||
          scope.includes(P2Role.DC_TEAM) ||
          scope.includes(P2Role.RUNNER)
        );
      })
      .map((scopeId) => {
        return scopeId.split(':')[2];
      });
  }

  get track(): TrackEvents {
    const aiDescriptionGenerated = ({
      itemLabel,
      menuName,
      brandName,
      LocalMenuGroupName,
      description,
    }: {
      itemLabel: string;
      menuName: string;
      brandName: string;
      LocalMenuGroupName: string;
      description: string;
    }) => {
      window.heap.track(EVENTS.AI_DESCRIPTION_GENERATED, {
        item: itemLabel,
        menu: menuName,
        brand: brandName,
        local_menu_group: LocalMenuGroupName,
        description,
      });
    };

    const aiImageGenerated = ({
      itemLabel,
      menuName,
      brandName,
      LocalMenuGroupName,
    }: {
      itemLabel: string;
      menuName: string;
      brandName: string;
      LocalMenuGroupName: string;
    }) => {
      this.aiImageGenerated = true;
      window.heap.track(EVENTS.AI_IMAGE_GENERATED, {
        item: itemLabel,
        menu: menuName,
        brand: brandName,
        local_menu_group: LocalMenuGroupName,
      });
    };

    const aiImageUsed = ({
      itemLabel,
      menuName,
      brandName,
      LocalMenuGroupName,
    }: {
      itemLabel: string;
      menuName: string;
      brandName: string;
      LocalMenuGroupName: string;
    }) => {
      this.aiImageGenerated = false;
      window.heap.track(EVENTS.AI_IMAGE_USED, {
        item: itemLabel,
        menu: menuName,
        brand: brandName,
        local_menu_group: LocalMenuGroupName,
      });
    };

    return {
      aiDescriptionGenerated,
      aiImageGenerated,
      aiImageUsed,
    };
  }

  private get getPermissions() {
    return this.cdlUser.permissions.scopes;
  }

  private get isAdmin() {
    return this.hasRole(P2Role.ADMIN);
  }
}

export function heap(): HeapAnalytics {
  if (!instance) {
    instance = new HeapAnalytics();
  }
  return instance;
}
