<template>
  <Card>
    <template #title>
      {{ t('statusCard.header', '', { default: 'Status' }) }}
    </template>
    <template #content>
      <RadioButton
        v-for="item in statuses"
        :key="item.status"
        :id="item.status"
        name="status"
        :value="item.status"
        v-model="value"
      >
        {{ item.label }}
      </RadioButton>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RadioButton } from '../../../../core/controls/radio-button';
import { Card } from './../card';
import { Status } from '@centric-os/types';
import { capitalize } from 'lodash-es';
import { useField } from 'vee-validate';

const props = defineProps({
  useInitialValue: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const statuses = computed(() =>
  Object.values(Status).map((value) => ({
    status: value,
    label: t(`statusCard.${value}`, capitalize(value)),
  })),
);

const { value } = useField('status', null, {
  initialValue: props.useInitialValue ? Status.ACTIVE : undefined,
});
</script>

<script lang="ts">
export default {
  name: 'StatusCard',
  inheritAttrs: false,
};
</script>
