import { createRouter as vCreateRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import qs from 'qs';

export default (routes: RouteRecordRaw[]) =>
  vCreateRouter({
    history: createWebHistory(),
    routes,
    parseQuery: (search) => {
      return qs.parse(search, { allowDots: true, plainObjects: true });
    },
    stringifyQuery: (query) => {
      return qs.stringify(query, { allowDots: true, encode: false });
    },
  });
