<template>
  <LoadingApi />
  <GlobalToast />

  <!-- Regular confirm dialog -->
  <ConfirmDialog group="info" />

  <!-- Confirm dialog group for danger actions (such as delete) -->
  <ConfirmDialog group="danger" class="c-confirm-dialog-danger">
    <template #icon>
      <i class="material-symbols-rounded text-4xl mr-3">warning_amber</i>
    </template>
  </ConfirmDialog>

  <ScannerDialog />

  <router-view />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSetLanguage } from '@centric-os/platform/i18n';
import { useAuthStore } from '@centric-os/stores';
import { heap } from '@centric-os/helpers';
import { ScannerDialog } from './components/scanner-dialog';

useSetLanguage();
const authStore = useAuthStore();
const { cdlUser, usedSSO } = storeToRefs(authStore);
useSetLanguage();

heap().init(cdlUser, usedSSO, authStore.getRole, authStore.hasRole);
</script>
