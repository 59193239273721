import {
  type PaginationConfig,
  PaginationQueryKeys,
  type QueryParams,
  type RecordListMeta,
  SortOrder,
} from '@centric-os/types';
import type { PiniaPluginContext, Store } from 'pinia';
import { computed, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter, type LocationQueryRaw } from 'vue-router';
import { isEqual, cloneDeep } from 'lodash';

export const defaultPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'createdAt',
    sortOrder: SortOrder.DESC,
    search: null,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export const PaginationPlugin = () => {
  return ({ options, store }: PiniaPluginContext) => {
    if (!options.pagination) return;
    const defaultConfig = cloneDeep(options.paginationConfig || defaultPaginationConfig);
    const pagination = reactive(cloneDeep(defaultConfig));

    store.$state.pagination = pagination;
    store.pagination = pagination;
    store.updatePaginationWithMeta = (meta: any) => {
      const entries = Object.entries(meta);
      const validMetaKeys = Object.keys(store.pagination.metaParams);

      for (const [key, v] of entries) {
        if (!validMetaKeys.includes(key)) continue;
        (store.pagination.metaParams as any)[key] = v;
      }
    };
    store.resetPagination = () => {
      store.$patch({ pagination: <any>cloneDeep(defaultConfig) });
    };
    store.getDefaultPaginationConfig = () => {
      return cloneDeep(defaultConfig);
    };
  };
};

interface StorePaginationOptions {
  updateQuery?: boolean;
}

export const useStorePagination = (
  store: Store,
  options: StorePaginationOptions = { updateQuery: true },
): { [key: string]: any } => {
  if (!store.pagination) throw new Error('store does not contain pagination');

  const sortOrderNumeric = computed({
    get() {
      return store.pagination?.queryParams?.sortOrder === SortOrder.ASC ? 1 : -1;
    },
    set(value: number) {
      store.pagination.queryParams.sortOrder = value === 1 ? SortOrder.ASC : SortOrder.DESC;
    },
  });

  if (options.updateQuery) {
    const route = useRoute();
    const router = useRouter();
    const defaultConfig = store.getDefaultPaginationConfig();
    // this watch is to update properly the store based on a typed URL
    watch(
      () => route.query,
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) return;

        const keyValues = <string[]>Object.keys(defaultConfig.queryParams);

        const newKeyValues = <string[]>Object.keys(newValue);

        for (const key in newValue) {
          let newPropertyValue: string | number = <string>newValue[key];
          if (key === PaginationQueryKeys.LIMIT || key === PaginationQueryKeys.PAGE) {
            newPropertyValue = parseInt(newPropertyValue);
          }

          const storeValue = store.pagination.queryParams[key];

          if (!keyValues.includes(key) || storeValue === newPropertyValue) continue;
          store.pagination.queryParams[key] = <string | number>newPropertyValue;
        }

        keyValues.forEach((key) => {
          if (newKeyValues.includes(key)) return;
          store.pagination.queryParams[key] = defaultConfig.queryParams[key];
        });
      },
      { immediate: true },
    );
    watch(
      () => cloneDeep(store.pagination.queryParams as QueryParams), // cloning to get proper old/new values
      (newValue, oldValue) => {
        if (!newValue) return;
        const currentQuery = route.query;
        const paginationKeys = Object.keys(defaultPaginationConfig.queryParams);
        const nonPaginationQuery = Object.keys(currentQuery).reduce((acc: any, key) => {
          // limit non-paginated query to just appcues to accomodate its edge case
          if (!paginationKeys.includes(key) && key === 'appcue') {
            acc[key] = currentQuery[key];
          }
          return acc;
        }, {});
        const newRoute = { ...route, query: { ...newValue, ...nonPaginationQuery } };
        if (newValue && oldValue && newValue.page === oldValue.page) {
          newRoute.query.page = 1;
        }
        'limit' in route.query
          ? router.push({
              path: route.path,
              query: newRoute.query as LocationQueryRaw,
            })
          : router.replace({
              path: route.path,
              query: newRoute.query as LocationQueryRaw,
            });
      },
      { immediate: true, deep: true },
    );
  }

  return {
    ...toRefs(store.pagination),
    ...toRefs(store.pagination.queryParams),
    sortOrderNumeric,
    reset: store.resetPagination,
  };
};

declare module 'pinia' {
  export interface PiniaCustomProperties {
    updatePaginationWithMeta?: (meta: RecordListMeta) => void;
    resetPagination?: () => void;
    getDefaultPaginationConfig: () => PaginationConfig;
    pagination?: PaginationConfig;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface PiniaCustomStateProperties<S> {
    pagination?: PaginationConfig;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    pagination?: boolean;
    paginationConfig?: PaginationConfig;
  }
}
