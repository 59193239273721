import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { OrdersRouteNames, OrdersRoutePathNames } from '../../enums';

import detailsRoute from './components/details/order-details';
import rawdataRoute from './components/raw-data/order-more-info';
import orderNotFoundRoute from './components/order-not-found';
import orderRefundRoute from './components/order-refund';

const Component = () => import('./OrderEntity.vue');

export default {
  path: buildChildURL(OrdersRoutePathNames.DETAILS),
  name: OrdersRouteNames.ORDER_ENTITY,
  redirect: { name: detailsRoute.name },
  children: [detailsRoute, rawdataRoute, orderNotFoundRoute, orderRefundRoute],
  component: Component,
} as RouteRecordRaw;
