import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../../../enums';

const Component = () => import('./BrandViews.vue');

export default {
  path: buildChildURL(
    GlobalMenuGroupsRoutePathNames.MENUS,
    GlobalMenuGroupsRoutePathNames.MENUS_ID,
  ),
  name: GlobalMenuGroupsRouteNames.BRAND_MENUS,
  component: Component,
} as RouteRecordRaw;
