<template>
  <div
    id="tooltip-container"
    ref="containerRef"
    class="w-full h-full flex align-items-center"
    v-tooltip.bottom="
      elementOverflowed && {
        value: `${columnData}`,
        escape: true,
        class: 'list-cell-tooltip',
      }
    "
  >
    <span id="text" ref="valuesRef" class="overflow-hidden text-ellipsis whitespace-nowrap">
      {{ columnData }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { ref, watch } from 'vue';

interface Props {
  columnData: string;
}

defineProps<Props>();

// Refs for the container and values elements
const valuesRef = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);

// Use useElementBounding for precise size calculations
const { width: elementWidth } = useElementBounding(valuesRef);
const { width: containerWidth } = useElementBounding(containerRef);

const elementOverflowed = ref(false);

// Watch the widths and determine if the content overflows
watch(
  [elementWidth, containerWidth],
  () => {
    // If elementWidth === containerWidth, we can infer there is an overflow/ellipsis and enable the tooltip on hover
    if (elementWidth.value === containerWidth.value) {
      elementOverflowed.value = true;
      return;
    }
    elementOverflowed.value = false;
  },
  { immediate: true },
);
</script>

<style scoped lang="scss">
.list {
  max-width: fit-content;
}
</style>
