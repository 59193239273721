<template>
  <div :class="rootClasses">
    <label v-if="$slots.label" id="internal-input-label-id">
      <slot name="label" />
    </label>

    <slot v-if="$slots.startAdorment" name="startAdorment" />

    <PVInputText
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      ref="input"
    />

    <slot v-if="$slots.endAdorment" name="endAdorment" />

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs, useSlots, ref } from 'vue';
import PVInputText from 'primevue/inputtext';
import { useInputState } from '../composables';
import { useFocus } from '@vueuse/core';

const props = defineProps({
  placeholder: String,
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();
const slots = useSlots();

const value = defineModel<any>();

const { focused, hovered, classes } = useInputState(attrs);

const rootClasses = computed(() => [
  ...classes.value,
  {
    'p-input-icon-left': slots.startAdorment,
    'p-input-icon-right': slots.endAdorment,
  },
]);

const input = ref();
useFocus(input, { initialValue: props.autofocus });

defineExpose({
  value,
});
</script>

<script lang="ts">
export default {
  name: 'InputText',
  inheritAttrs: false,
};
</script>
