import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { OrdersRoutePathNames, OrdersRouteNames } from './enums';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(OrdersRoutePathNames.ORDERS),
  name: OrdersRouteNames.ORDERS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: routes,
};
