import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../enums';
import { useAuthStore } from '@centric-os/stores';
import { MenuScopeActions, MenuScopeServices } from '@centric-os/types';

const Component = () => import('./LocalMenuGroupList.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.LIST),
  name: LocalMenuGroupRouteNames.LOCAL_MENUS_LIST,
  component: Component,
  beforeEnter: () => {
    const authStore = useAuthStore();
    const scopesIds = authStore.getScopeIds(
      MenuScopeActions.READ,
      MenuScopeServices.LOCAL_MENU_GROUP,
    );
    const shouldRedirect = scopesIds?.length === 1 && scopesIds?.[0] !== '*';
    if (shouldRedirect) {
      const id = scopesIds[0];
      return {
        name: LocalMenuGroupRouteNames.LOCAL_MENU_DETAILS,
        params: { id },
        replace: true,
      };
    }
    return true;
  },
} as RouteRecordRaw;
