<template>
  <div
    :id="id"
    :class="['c-header-bar bg-white fixed w-full z-3', { 'c-show-bar': showHeader }]"
  ></div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { GlobalEmitterKey } from './../../emitter';
import { useHeaderBar } from './hooks';

const emitter = inject(GlobalEmitterKey);
const showHeader = ref(false);
const { id } = useHeaderBar();

emitter.on('toggleHeader', (e) => {
  showHeader.value = e;
});
</script>

<style lang="scss" scoped>
.c-header-bar {
  height: var(--header-height);
  top: calc(-1 * var(--header-height));
  transition: top 0.3s;

  &.c-show-bar {
    top: 0px;
    border-bottom: 1px solid rgba(var(--neutral-dark-l2-color), 0.75);
  }
}
</style>
