<template>
  <div :class="rootClasses">
    <label v-if="$slots.label" class="mb-3">
      <slot name="label"> </slot>
    </label>

    <Listbox
      v-model="value"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <template #option="{ option }">
        <div class="flex align-items-center option">
          <ListBoxCheckbox
            v-if="checkbox"
            id="list-box-checkbox"
            :option="option"
            v-model="value"
          />
          <span>
            {{ getOptionLabel(option) }}
          </span>
          <div
            v-if="listItemPrimaryIcon || listItemSecondaryIcon || listItemTertiaryIcon"
            class="flex align-items-center ml-auto"
            style="gap: 1rem"
          >
            <i
              v-if="listItemPrimaryIcon"
              id="list-item-primary-action"
              :class="listItemPrimaryIcon"
              @click.stop="emit('listItemPrimaryAction', option)"
            />
            <i
              v-if="listItemSecondaryIcon"
              id="list-item-secondary-action"
              :class="listItemSecondaryIcon"
              @click.stop="emit('listItemSecondaryAction', option)"
            />
            <i
              v-if="listItemTertiaryIcon"
              id="list-item-tertiary-action"
              :class="listItemTertiaryIcon"
              @click.stop="emit('listItemTertiaryAction', option)"
            />
          </div>
        </div>
      </template>
    </Listbox>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script lang="ts" setup>
import Listbox from 'primevue/listbox';
import { useAttrs } from 'vue';
import { useInputState } from '../composables';
import ListBoxCheckbox from './ListBoxCheckbox.vue';

defineProps({
  customFilter: Boolean,
  virtualScrollerOptions: Object,
  checkbox: Boolean,
  listItemPrimaryIcon: String,
  listItemSecondaryIcon: String,
  listItemTertiaryIcon: String,
});

const emit = defineEmits([
  'hide',
  'search',
  'listItemPrimaryAction',
  'listItemSecondaryAction',
  'listItemTertiaryAction',
]);
const attrs = useAttrs();

const value = defineModel<any>();
const { focused, hovered, classes: rootClasses } = useInputState(attrs);

// Get the option label
const getOptionLabel = (option: any) => {
  return typeof option === 'object' ? option?.[attrs?.optionLabel] : option;
};
</script>
