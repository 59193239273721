import type { RouteRecordRaw } from 'vue-router';
import { OrdersRouteNames } from '../../../../../enums';

const Component = () => import('./OrderDetails.vue');

export default {
  path: '',
  name: OrdersRouteNames.ORDER_DETAILS,
  component: Component,
} as RouteRecordRaw;
