import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { ReportsRouteNames, ReportsRoutePathNames } from '../../enums';

const Component = () => import('./SalesMix.vue');

export default {
  path: buildChildURL(ReportsRoutePathNames.SALES_MIX),
  name: ReportsRouteNames.SALES_MIX,
  component: Component,
} as RouteRecordRaw;
