import axios, { type AxiosError, type AxiosInstance } from 'axios';
import { getApiError } from '@centric-os/helpers';
import { useAuthStore, useLoadingStore } from '@centric-os/stores';
import type { ApiResponseError, AxiosCustomConfig } from '@centric-os/types';

let authStore: any = null;
let loadingStore: any = null;

const baseURL: string = import.meta.env.VITE_ENDPOINT_BASE_URL;

export const createAxiosInstance = (config: AxiosCustomConfig = {}): AxiosInstance => {
  const api = axios.create({
    baseURL,
    ...config,
    paramsSerializer: (params) => {
      // Custom serialization logic
      const serializedParams = Object.keys(params)
        .map((key) => {
          const value = params[key];

          if (value == null) {
            // Skip null or undefined values
            return '';
          }

          if (typeof value === 'object' && Object.keys(value).length > 0) {
            return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
          } else if (typeof value !== 'object') {
            return `${key}=${encodeURIComponent(value)}`;
          }

          return ''; // Skip empty objects
        })
        .filter((param) => param) // Remove empty strings
        .join('&');

      return serializedParams;
    },
  });

  api.interceptors.request.use(async (req: AxiosCustomConfig) => {
    if (!loadingStore) {
      loadingStore = useLoadingStore();
    }

    if (req.url?.startsWith('/user/auth')) return req;

    if (!authStore) {
      authStore = useAuthStore();
    }

    const token = await authStore.getValidAccessToken();

    if (token) {
      if (!config?.skipAuthorization && !req.headers.authorization) {
        req.headers.authorization = `Bearer ${token}`;

        loadingStore.loading = true;
      } else if (config?.skipAuthorization) {
        loadingStore.loading = true;
      }
    }

    return req;
  });

  api.interceptors.response.use(
    (response) => {
      loadingStore.loading = false;

      return response;
    },
    (error: AxiosError<ApiResponseError>) => {
      loadingStore.loading = false;
      return Promise.reject(getApiError(error));
    },
  );

  return api;
};
