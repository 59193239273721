export enum OrdersRoutePathNames {
  ORDERS = 'orders',
  LIST = '',
  CREATE = 'create',
  DETAILS = ':id',
  MORE_INFO = 'more_info',
  REFUND = 'refund',
  ENTITY = 'entity',
  NOT_FOUND = 'not-found',
  WILD_CARD = '*',
}
