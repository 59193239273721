export const navigationEn = {
  navigation: {
    titlebar: {
      logout: 'Logout',
    },
    general: 'General',
    external: 'Integrated partners',
    apps: 'Apps',
    home: 'Home',
    sites: {
      root: 'Sites',
    },
    adminPanel: {
      nav: 'Administration',
      userManagement: {
        root: 'Users',
      },
    },
    orders: {
      root: 'Orders',
    },
    menus: {
      root: 'Menus',
      global: 'Global Menus',
      local: 'Local Menus',
    },
    libraries: {
      root: 'Libraries',
    },
    reports: {
      root: 'Reports',
      salesSummary: 'Sales Summary',
      salesDistribution: 'Sales Distribution',
      salesMix: 'Sales Mix',
      refundReport: 'Refunds',
    },
    operatorAnalytics: {
      root: 'Operator Analytics',
    },
    fpPromo: {
      root: 'FreedomPay',
    },
    scanner: {
      root: 'Barcode Scanner',
    },
  },
};
