<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, handleBlur, validate, errorMessage }">
      <ListBox
        :modelValue="value"
        @update:modelValue="handleChange"
        @blur="handleBlur"
        @hide="validate"
        :class="{ 'p-invalid': errorMessage }"
        :virtualScrollerOptions="virtualScrollerOptions"
        v-bind="$attrs"
        @listItemPrimaryAction="emit('listItemPrimaryAction', $event)"
        @listItemSecondaryAction="emit('listItemSecondaryAction', $event)"
        @listItemTertiaryAction="emit('listItemTertiaryAction', $event)"
      >
        <template v-if="label" #label>{{ label }}</template>

        <template v-if="$slots.actions" #actions>
          <slot name="actions" />
        </template>

        <template v-if="errorMessage" #helperText>{{ errorMessage }}</template>
      </ListBox>
    </template>
  </FieldValidator>
</template>
<script lang="ts" setup>
import { ListBox } from './../../../core/controls';
import { FieldValidator } from './../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  virtualScrollerOptions: {
    type: Object,
  },
});

const emit = defineEmits([
  'listItemPrimaryAction',
  'listItemSecondaryAction',
  'listItemTertiaryAction',
]);
</script>
