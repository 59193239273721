<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <InputText
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="inputText"
        @keydown.enter="handleEnter"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </InputText>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { InputText } from './../../../core/controls';
import { FieldValidator } from './../field-validator';
import useSubmitFormProvider from '../../../core/controls/composables/input/use-submit-form-provider';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
});

const inputText = ref<InstanceType<typeof InputText>>();

const { handleEnter } = useSubmitFormProvider();

defineExpose({ inputText });
</script>
