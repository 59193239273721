<template>
  <TabView>
    <TabPanel v-for="(tab, index) in tabItems" :key="index">
      <template #header>
        <SidebarFormTabHeader :label="tab?.label" :tabErrors="tabErrors" />
      </template>
      <div
        class="overflow-auto pt-5"
        :style="contentHeight"
        ref="sidebarScroll"
        :class="contentPadding"
      >
        <TabValidateProvider :label="tab?.label" @onValidate="handleTabErrors">
          <template #content>
            <component :is="tab?.component" v-bind="tab?.props" />
          </template>
        </TabValidateProvider>
      </div>
    </TabPanel>
  </TabView>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import type {
  SidebarFormTabComponentItem,
  SidebarFormTabErrors,
  SidebarFormValidationEmitPayload,
} from '@centric-os/types';
import SidebarFormTabHeader from '../sidebar-form-tab-header/SidebarFormTabHeader.vue';
import TabValidateProvider from '../tab-validate-provider/TabValidateProvider.vue';
import { isEmpty, set } from 'lodash';

defineProps({
  tabItems: {
    type: Array as PropType<SidebarFormTabComponentItem[]>,
    default: () => [],
  },
  contentHeight: {
    type: String,
    required: true,
  },
  contentPadding: {
    type: String,
    required: true,
  },
});

const tabErrorsValue = defineModel('tabErrors', {
  type: Object as PropType<SidebarFormTabErrors>,
});

const handleTabErrors = (validateEvent: SidebarFormValidationEmitPayload): void => {
  const { fieldName, label, hasError } = validateEvent;

  const currentErrors = { ...tabErrorsValue.value };

  const fieldNameExists = currentErrors?.[label]?.[fieldName];

  if (hasError && fieldNameExists) {
    // If the error is already present, no need to update the errors
    return;
  }
  if (hasError && !fieldNameExists) {
    // If the error is not present, add the error
    set(currentErrors, `${label}.${fieldName}`, hasError);
  }
  if (!hasError && fieldNameExists) {
    // If the error is present, remove the error
    delete currentErrors[label][fieldName];
    // If there are no errors for the label, remove the label
    if (isEmpty(currentErrors[label])) {
      delete currentErrors[label];
    }
  }
  tabErrorsValue.value = currentErrors;
};
</script>
