<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVCalendar
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      :dateFormat="dateFormat"
      id="calendar-input"
      ref="calendar"
      :selectionMode="selectionMode"
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot v-if="slot" :name="slot" v-bind="scope" />
      </template>

      <template #footer v-if="selectionMode === 'range' && showCustomFooter">
        <div class="p-datepicker-buttonbar">
          <div class="flex flex-wrap">
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.todayLabel', 'Today')"
                @click="onTodayButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.yesterdayLabel', 'Yesterday')"
                @click="onYesterdayButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.currentWeekLabel', 'Current Week')"
                @click="onCurrentWeekButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.lastWeekLabel', 'Last Week')"
                @click="onLastWeekButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.currentMonthLabel', 'Current Month')"
                @click="onCurrentMonthButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
            <div class="w-full md:w-6 p-1 md:p-2">
              <Button
                type="button"
                :label="t('calendar.lastMonthLabel', 'Last Month')"
                @click="onLastMonthButtonClick($event)"
                :class="customTodayButtonClasses"
              />
            </div>
          </div>
        </div>
      </template>
    </PVCalendar>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, useAttrs, type PropType } from 'vue';
import PVCalendar from 'primevue/calendar';
import { useInputState } from '../composables';
import { Button } from '../../buttons';
import { useI18n } from 'vue-i18n';
import { ButtonStateClass } from '../../buttons/enums';
import { DateTime } from 'luxon';

const props = defineProps({
  placeholder: String,
  label: String,
  helperText: String,
  showCustomFooter: {
    type: Boolean,
    default: false,
  },
  showClearIcon: {
    type: Boolean,
    default: false,
  },
  selectionMode: {
    type: String,
    default: 'single',
  },
  dateFormat: {
    type: String,
    default: 'M dd, yy',
  },
});

const emit = defineEmits(['change']);

const value = defineModel({ type: [Array, Date, String] as PropType<Array<Date> | Date | String> });

const attrs = useAttrs();
const { t } = useI18n();
const customTodayButtonClasses = `${ButtonStateClass.TEXT} w-full`;

const calendar = ref<InstanceType<typeof PVCalendar>>();

const date = DateTime.local().toJSDate();
const weekStartDate = DateTime.local().startOf('week').minus({ day: 1 });
const weekEndDate = DateTime.local().endOf('week').minus({ day: 1 });

const onTodayButtonClick = (event: Event) => {
  const dates = [date, date];
  calendar.value.$emit('today-click', dates);
  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onYesterdayButtonClick = (event: Event) => {
  const yesterday = DateTime.local().minus({ day: 1 }).toJSDate();
  const dates = [yesterday, yesterday];

  calendar.value.$emit('today-click', dates);
  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onCurrentWeekButtonClick = (event: Event) => {
  const firstDay = weekStartDate.toJSDate();
  const lastDay = weekEndDate.toJSDate();

  let dates = [];

  if (date.getDate() < lastDay.getDate()) {
    dates = [firstDay, date];
  } else {
    dates = [firstDay, lastDay];
  }

  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onLastWeekButtonClick = (event: Event) => {
  const dates = [
    weekStartDate.minus({ week: 1 }).toJSDate(),
    weekEndDate.minus({ week: 1 }).toJSDate(),
  ];
  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onCurrentMonthButtonClick = (event: Event) => {
  const firstDay = DateTime.local().startOf('month').toJSDate();
  const lastDay = DateTime.local().endOf('month').toJSDate();

  let dates = [];
  if (date.getMonth() === lastDay.getMonth() && lastDay.getDate() > date.getDate()) {
    dates = [firstDay, date];
  } else {
    dates = [firstDay, lastDay];
  }

  calendar.value.$emit('today-click', dates);
  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onLastMonthButtonClick = (event: Event) => {
  const firstDay = DateTime.local().minus({ month: 1 }).startOf('month').toJSDate();
  const lastDay = DateTime.local().minus({ month: 1 }).endOf('month').toJSDate();

  let dates = [firstDay, lastDay];
  calendar.value.$emit('today-click', dates);
  calendar.value.$emit('date-select', dates);
  (calendar.value as any).overlayVisible = false;
  value.value = dates;
  event.preventDefault();
};

const onClearClick = (event: any) => {
  emit('update:modelValue', null);
  emit('change', { originalEvent: event, value: null });
};

const openCalendar = () => {
  (calendar.value as any).overlayVisible = true;
  calendar.value.$emit('show');
};

const { focused, hovered, classes: rootClasses } = useInputState(attrs);
</script>

<script lang="ts">
export default { name: 'Calendar', inheritAttrs: false };
</script>
