import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../../../enums';

const Component = () => import('./LocalBrandUpdates.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.UPDATES),
  name: LocalMenuGroupRouteNames.BRAND_UPDATES,
  component: Component,
} as RouteRecordRaw;
