<template>
  <div :class="rootClasses">
    <label v-if="$slots.label" :id="`input-label-${id}`">
      <slot name="label" />
    </label>

    <PVInputMask
      :id="id"
      :mask="mask"
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
      ref="input"
    />

    <span v-if="$slots.helperText" class="caption" :id="`helper-text-${id}`">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs, useSlots, ref } from 'vue';
import PVInputMask from 'primevue/inputmask';
import { useInputState, type ClassType } from '../composables';
import { useFocus } from '@vueuse/core';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  mask: {
    type: String,
    required: true,
  },
  placeholder: String,
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();
const slots = useSlots();

const value = defineModel<any>();

const { focused, hovered, classes } = useInputState(attrs);

const rootClasses = computed<ClassType[]>(() => [...classes.value]);

const input = ref();
useFocus(input, { initialValue: props.autofocus });

defineExpose({
  value,
});
</script>

<script lang="ts">
export default {
  name: "InputMask",
  inheritAttrs: false,
};
</script>
