import { createI18n as vueCreateI18n, type I18nOptions, useI18n } from 'vue-i18n';
import type { CafeI18n } from '@centric-os/types';
import { numberFormats } from './locales/number-formats';
import { datetimeFormats } from './locales/datetime-formats';
import { watchEffect } from 'vue';

export const defaultI18nOptions: I18nOptions = {
  legacy: false,
  fallbackLocale: 'en',
  locale: 'en',
  numberFormats,
  datetimeFormats,
};

export function createI18n(options: I18nOptions = {}) {
  const mergedOptions: I18nOptions = { ...defaultI18nOptions, ...options };
  const instance = vueCreateI18n(mergedOptions) as CafeI18n;

  return instance;
}

export function useSetLanguage() {
  const composer: any = useI18n({ useScope: 'global' });
  const { locale } = composer;
  watchEffect(() => {
    document.querySelector('html')?.setAttribute('lang', locale.value as string);
  });

  return { ...composer };
}
