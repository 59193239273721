import type { InjectionKey } from 'vue';

export type DocumentTitleLocaleOptions = {
  interpolationProperty?: string;
  wrapperKey?: string;
};

export const DocumentTitleLocaleKey: InjectionKey<DocumentTitleLocaleOptions> =
  Symbol('PageTitleGlobalLocale');

export const SetDocumentTitleKey: InjectionKey<(newTitle: string | null | undefined) => void> =
  Symbol('SetPageTitleKey');
