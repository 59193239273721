<template>
  <div class="grid grid-nogutter">
    <div class="col-12 md:col-4 md:pr-3">
      <div class="flex h-full">
        <template v-if="$slots.customTitle">
          <slot name="customTitle" />
        </template>

        <template v-else>
          <div class="align-self-center flex align-items-center w-full" style="min-height: 40px">
            <h2 id="table-title" class="font-semibold">{{ title }}</h2>
          </div>
        </template>
      </div>
    </div>
    <div class="col-12 md:col-2 md:pr-3 pt-3 md:pt-0" v-if="$slots.tableActions">
      <div class="flex h-full">
        <div class="align-self-center flex align-items-center">
          <slot name="tableActions" />
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 pt-3 md:pt-0" :class="{ 'md:col-8': !$slots.tableActions }">
      <div class="flex md:justify-content-end h-full">
        <div class="align-self-center flex align-items-center">
          <slot name="buttonActions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TableHeader',
};
</script>

<script setup lang="ts">
defineProps({
  title: {
    type: String,
  },
});
</script>
