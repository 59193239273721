import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import viewsRoute from './components/brand-views';
import modifierRoute from './components/brand-modifiers';
import itemRoute from './components/brand-items';
import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../enums';

const Component = () => import('./BrandDetails.vue');

export default {
  path: buildChildURL(
    GlobalMenuGroupsRoutePathNames.ID,
    GlobalMenuGroupsRoutePathNames.BRAND_DETAILS,
    GlobalMenuGroupsRoutePathNames.BRAND_ID,
  ),
  name: GlobalMenuGroupsRouteNames.BRAND_DETAILS,
  component: Component,
  redirect: { name: viewsRoute.name },
  children: [viewsRoute, modifierRoute, itemRoute],
} as RouteRecordRaw;
