import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../../../enums';

const Component = () => import('./BrandItems.vue');

export default {
  path: buildChildURL(GlobalMenuGroupsRoutePathNames.ITEMS),
  name: GlobalMenuGroupsRouteNames.BRAND_ITEMS,
  component: Component,
} as RouteRecordRaw;
