/* eslint-disable vue/no-reserved-component-names */
/* eslint-disable vue/multi-word-component-names */
import type { App } from 'vue';
import HeaderBar from './header-bar/HeaderBar.vue';
import RecordSaveBar from './record-save-bar';
import GlobalToast from './global-toast';
import LoadingApi from './loading-api/LoadingApi.vue';
import { DeleteFooter } from './delete-footer';
import { OrderStatusTag } from './order-status-tag';
import { DeliveryAppTag } from './delivery-app-tag';
import { RefundStatusTag } from './refund-status-tag';

import {
  Accordion,
  AccordionTab,
  AppLogo,
  AutoComplete,
  AutoCompleteBox,
  AutocompleteBoxField,
  AutocompleteField,
  Avatar,
  Breadcrumb,
  Button,
  Calendar,
  CalendarField,
  Card,
  Checkbox,
  CheckboxField,
  Chip,
  Chips,
  ChipsField,
  Col1,
  Col2,
  Col3,
  Col4,
  Col5,
  Col6,
  Col7,
  Col8,
  Col9,
  Col10,
  Col11,
  Col12,
  Column,
  ConfirmDialog,
  ContentPage,
  ContentPageForm,
  ContentSubHeader,
  ContextMenu,
  CurrencyField,
  CustomAccordion,
  DataTable,
  DateCell,
  Dialog,
  Divider,
  Dropdown,
  DropdownField,
  Field,
  FieldArrayValidator,
  FieldValidator,
  FileUpload,
  Form,
  FullCalendar,
  Grid,
  IconButton,
  Image,
  ImageListItem,
  ImageSelect,
  ImageSelectField,
  InputCurrency,
  InputMask,
  InputNumber,
  InputSwitch,
  InputSwitchStatus,
  InputText,
  InputTextSearch,
  ListBox,
  ListBoxField,
  ListCell,
  MaskField,
  Menu,
  Message,
  MultiSelect,
  MultiSelectField,
  NumberField,
  OrderList,
  OverlayPanel,
  Page,
  Paginator,
  Password,
  PasswordField,
  PickList,
  PrimaryRecordLayout,
  ProgressBar,
  ProgressSpinner,
  RadioButton,
  RadioButtonField,
  RecordLayout,
  ReorderList,
  ReorderListField,
  RoleLinkCell,
  SecondaryRecordLayout,
  SelectButton,
  SelectButtonField,
  Sidebar,
  SidebarForm,
  Skeleton,
  Splitter,
  SplitterLayout,
  SplitterPanel,
  StatusCard,
  StatusTag,
  Sticky,
  StoreTable,
  SwitchField,
  SwitchStatusField,
  TabMenu,
  TabPanel,
  TabView,
  TableEmpty,
  TableHeader,
  TableLayout,
  TableSearch,
  Tag,
  TertiaryRecordLayout,
  Textarea,
  TextareaField,
  TextButton,
  TextField,
  Toast,
  ToggleButton,
  Toolbar,
  Tree,
  TreeTable,
  TriStateCheckbox,
  UserLinkCell,
} from '@centric-os/components';

export const registerAppComponents = (app: App) => {
  app.component('Accordion', Accordion);
  app.component('AccordionTab', AccordionTab);
  app.component('AppLogo', AppLogo);
  app.component('AutoComplete', AutoComplete);
  app.component('AutocompleteField', AutocompleteField);
  app.component('AutoCompleteBox', AutoCompleteBox);
  app.component('AutocompleteBoxField', AutocompleteBoxField);
  app.component('Avatar', Avatar);
  app.component('Breadcrumb', Breadcrumb);
  app.component('Button', Button);
  app.component('Calendar', Calendar);
  app.component('CalendarField', CalendarField);
  app.component('Card', Card);
  app.component('Checkbox', Checkbox);
  app.component('CheckboxField', CheckboxField);
  app.component('Chip', Chip);
  app.component('Chips', Chips);
  app.component('ChipsField', ChipsField);
  app.component('Col1', Col1);
  app.component('Col2', Col2);
  app.component('Col3', Col3);
  app.component('Col4', Col4);
  app.component('Col5', Col5);
  app.component('Col6', Col6);
  app.component('Col7', Col7);
  app.component('Col8', Col8);
  app.component('Col9', Col9);
  app.component('Col10', Col10);
  app.component('Col11', Col11);
  app.component('Col12', Col12);
  app.component('Column', Column);
  app.component('ConfirmDialog', ConfirmDialog);
  app.component('ContentPage', ContentPage);
  app.component('ContentPageForm', ContentPageForm);
  app.component('ContentSubHeader', ContentSubHeader);
  app.component('ContextMenu', ContextMenu);
  app.component('CurrencyField', CurrencyField);
  app.component('CustomAccordion', CustomAccordion);
  app.component('DataTable', DataTable);
  app.component('DateCell', DateCell);
  app.component('DeleteFooter', DeleteFooter);
  app.component('DeliveryAppTag', DeliveryAppTag);
  app.component('Dialog', Dialog);
  app.component('Divider', Divider);
  app.component('Dropdown', Dropdown);
  app.component('DropdownField', DropdownField);
  app.component('Field', Field);
  app.component('FieldArrayValidator', FieldArrayValidator);
  app.component('FieldValidator', FieldValidator);
  app.component('FileUpload', FileUpload);
  app.component('Form', Form);
  app.component('FullCalendar', FullCalendar);
  app.component('GlobalToast', GlobalToast);
  app.component('HeaderBar', HeaderBar);
  app.component('Grid', Grid);
  app.component('IconButton', IconButton);
  app.component('Image', Image);
  app.component('ImageListItem', ImageListItem);
  app.component('ImageSelect', ImageSelect);
  app.component('ImageSelectField', ImageSelectField);
  app.component('InputCurrency', InputCurrency);
  app.component('InputMask', InputMask);
  app.component('InputNumber', InputNumber);
  app.component('InputSwitch', InputSwitch);
  app.component('InputSwitchStatus', InputSwitchStatus);
  app.component('InputText', InputText);
  app.component('InputTextSearch', InputTextSearch);
  app.component('ListBox', ListBox);
  app.component('ListBoxField', ListBoxField);
  app.component('ListCell', ListCell);
  app.component('LoadingApi', LoadingApi);
  app.component('MaskField', MaskField);
  app.component('Menu', Menu);
  app.component('Message', Message);
  app.component('MultiSelect', MultiSelect);
  app.component('MultiSelectField', MultiSelectField);
  app.component('NumberField', NumberField);
  app.component('OrderList', OrderList);
  app.component('OrderStatusTag', OrderStatusTag);
  app.component('OverlayPanel', OverlayPanel);
  app.component('Page', Page);
  app.component('Paginator', Paginator);
  app.component('Password', Password);
  app.component('PasswordField', PasswordField);
  app.component('PickList', PickList);
  app.component('PrimaryRecordLayout', PrimaryRecordLayout);
  app.component('ProgressBar', ProgressBar);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('RadioButton', RadioButton);
  app.component('RadioButtonField', RadioButtonField);
  app.component('RecordLayout', RecordLayout);
  app.component('RecordSaveBar', RecordSaveBar);
  app.component('ReorderList', ReorderList);
  app.component('ReorderListField', ReorderListField);
  app.component('RoleLinkCell', RoleLinkCell);
  app.component('SecondaryRecordLayout', SecondaryRecordLayout);
  app.component('SelectButton', SelectButton);
  app.component('SelectButtonField', SelectButtonField);
  app.component('Sidebar', Sidebar);
  app.component('SidebarForm', SidebarForm);
  app.component('Skeleton', Skeleton);
  app.component('Splitter', Splitter);
  app.component('SplitterLayout', SplitterLayout);
  app.component('SplitterPanel', SplitterPanel);
  app.component('StatusCard', StatusCard);
  app.component('StatusTag', StatusTag);
  app.component('Sticky', Sticky);
  app.component('StoreTable', StoreTable);
  app.component('SwitchField', SwitchField);
  app.component('SwitchStatusField', SwitchStatusField);
  app.component('TabMenu', TabMenu);
  app.component('TabPanel', TabPanel);
  app.component('TabView', TabView);
  app.component('TableEmpty', TableEmpty);
  app.component('TableHeader', TableHeader);
  app.component('TableLayout', TableLayout);
  app.component('TableSearch', TableSearch);
  app.component('Tag', Tag);
  app.component('TertiaryRecordLayout', TertiaryRecordLayout);
  app.component('Textarea', Textarea);
  app.component('TextareaField', TextareaField);
  app.component('TextButton', TextButton);
  app.component('TextField', TextField);
  app.component('Toast', Toast);
  app.component('ToggleButton', ToggleButton);
  app.component('Toolbar', Toolbar);
  app.component('Tree', Tree);
  app.component('TreeTable', TreeTable);
  app.component('TriStateCheckbox', TriStateCheckbox);
  app.component('UserLinkCell', UserLinkCell);
  app.component('RefundStatusTag', RefundStatusTag);
};
