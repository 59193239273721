import { computed } from 'vue';

export default (className: string | undefined) => {
  const classes = computed(() =>
    className?.length
      ? {
          [className]: true,
        }
      : {},
  );

  return { classes };
};
