export enum CardHelperClasses {
  NO_SHADOWS = 'no-box-shadow',
  FULL_BORDERS = 'border-1',
  TOP_BORDER = 'border-top-1',
  BOTTOM_BORDER = 'border-bottom-1',
  LEFT_BORDER = 'border-left-1',
  RIGHT_BORDER = 'border-right-1',
  FULL_VIEWPORT = 'card-full-viewport',
  FULL_CONTENT_BODY = 'card-full-content-body',
}
