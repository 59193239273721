<template>
  <PVTag v-bind="{ ...$props, ...$attrs }">
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot v-if="slot" :name="slot" v-bind="scope" />
    </template>
  </PVTag>
</template>

<script setup lang="ts">
import PVTag from 'primevue/tag';

defineProps({
  rounded: {
    type: Boolean,
    default: true,
  },
});
</script>

<script lang="ts">
export default { name: 'Tag', inheritAttrs: false };
</script>
