<template>
  <Tag :class="classes">
    {{
      t(`${order_status?.replace(/_|-|\./g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}`)
    }}
  </Tag>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  order_status: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();

const classes = computed(() => ({
  'c-tag-neutral': props.order_status !== 'delivered',
}));
</script>
