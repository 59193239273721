import type { Status, RecordEntityId, RecordEntity } from './../records';
import type { Role } from './../roles';
export interface CreateUserPayload {
  email: string;
  createdById: RecordEntityId;
  status: Status;
}

export interface UpdateUserPayload {
  status: Status;
  roles?: Pick<Role, 'id'>[];
}

export interface User extends RecordEntity {
  company?: string;
  department?: string;
  email?: string;
  fullName?: string;
  firstName?: string;
  lastLoggedInAt?: string | number | Date;
  lastName?: string;
  locale?: string;
  meta?: JSON;
  oAuthId?: string;
  sAMAccountName?: string;
  telephone?: string;
  title?: string;
  username?: string;
  roles?: Role[];
}

export enum P2Role {
  SYS_ADMIN = 'sys_admin',
  ADMIN = 'admin',
  SITE_OPERATOR = 'site_operator',
  IM_USER = 'im_user',
  MENU_USER = 'menu_user',
  DC_TEAM = 'dc_team',
  RUNNER = 'runner',
}

export enum MenuRoles {
  GLOBAL_MENU_GROUP_ADMIN = 'global_menu_group_admin',
  LOCAL_MENU_GROUP_ADMIN = 'local_menu_group_admin',
  GLOBAL_MENU_GROUP_MANAGER = 'global_menu_group_manager',
  LOCAL_MENU_GROUP_MANAGER = 'local_menu_group_manager',
}

export const PermissionScope = {
  refundOrder: 'write:order:*',
  reports: 'report:c360:*',
  freedompayIncentives: 'promo:c360:fp',
  canManageDropoffLocations: 'write:deliverydestination:*',
};

export const AdminPanelPermissionScope = {
  users: '*:users',
};

export enum MenuScopeActions {
  READ = 'read',
  WRITE_NESTED = 'write_nested',
}
export enum MenuScopeServices {
  LOCAL_MENU_GROUP = 'local_menu_group',
  GLOBAL_MENU_GROUP = 'global_menu_group',
}
