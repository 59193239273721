<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <SelectButton
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
      >
        <!-- <template v-if="isInvalid" #helperText>{{ errorMessage }}</template> -->
      </SelectButton>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { SelectButton } from './../../../core/buttons/select-button';
import { FieldValidator } from '../field-validator';

defineProps({
  name: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default { name: 'SelectButtonField' };
</script>
