import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LibrariesRouteNames, LibrariesRoutePathNames } from '../../enums';

const Component = () => import('./LibrariesList.vue');

export default {
  path: buildChildURL(LibrariesRoutePathNames.LIST),
  name: LibrariesRouteNames.LIBRARIES_LIST,
  component: Component,
} as RouteRecordRaw;
