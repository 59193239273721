<template>
  <Tag :class="classes">
    {{ t(`refundStatus.${refundStatus}`) }}
  </Tag>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  isFullRefund: {
    type: Boolean,
    required: true,
  },
});

const { t } = useI18n();

const classes = computed(() => ({
  'c-tag-error3': true,
}));

const refundStatus = props.isFullRefund ? 'full' : 'partial';
</script>
