<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVChips
      v-model="value"
      :placeholder="finalPlaceholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    />

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Chips',
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import PVChips from 'primevue/chips';
import { useAttrs, computed } from 'vue';
import { useInputState } from '../composables';

const props = defineProps({
  modelValue: {
    type: Array<String>,
    default: null,
  },
  placeholder: String,
});

const attrs = useAttrs();

const value = defineModel({
  type: Array,
  default: null,
});

const { focused, hovered, classes: rootClasses } = useInputState(attrs);

// Fix placeholder issue displaying while having values
const finalPlaceholder = computed<string>(() => {
  return value.value?.length > 0 ? null : props.placeholder;
});

defineExpose({
  value,
});
</script>
