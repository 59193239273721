import type { TagComponentSeverities } from '../../../../../core';
import { useConfirm } from 'primevue/useconfirm';
import { type ComputedRef, ref } from 'vue';

export interface ConfirmDialogOptions {
  header: string | ComputedRef<string>;
  message: string | ComputedRef<string>;
  acceptLabel: string | ComputedRef<string>;
  rejectLabel: string | ComputedRef<string>;
  accept?: () => void;
  reject?: () => void;
  severity?: TagComponentSeverities.DANGER | TagComponentSeverities.INFO;
  acceptIcon?: string;
}

export default (confirmDialogContent: ConfirmDialogOptions) => {
  const confirm = useConfirm();

  const showConfirmDialog = (accept?: () => void, reject?: () => void): void => {
    const header = ref(confirmDialogContent.header);
    const message = ref(confirmDialogContent.message);
    const acceptLabel = ref(confirmDialogContent.acceptLabel);
    const rejectLabel = ref(confirmDialogContent.rejectLabel);
    const group = confirmDialogContent.severity;
    const acceptIcon = confirmDialogContent.acceptIcon;

    const isDanger = group === 'danger';

    confirm.require({
      message: message.value,
      header: header.value,
      acceptLabel: acceptLabel.value,
      rejectLabel: rejectLabel.value,
      acceptClass: isDanger ? 'p-button-danger' : '',
      acceptIcon,
      group: group || 'info',
      accept: confirmDialogContent.accept ? confirmDialogContent.accept : accept,
      reject: confirmDialogContent.reject ? confirmDialogContent.reject : reject,
    });
  };

  return {
    showConfirmDialog,
    close: confirm.close,
  };
};
