import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { SitesRoutePathNames, SitesRouteNames } from './enums';

const App = () => import('./App.vue');

export * from './enums';

export * from './routes';

export const route: RouteRecordRaw = {
  path: buildRootURL(SitesRoutePathNames.SITES),
  name: SitesRouteNames.SITES_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: routes,
};
