<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <InputMask
        :id="id"
        :mask="mask"
        :modelValue="value"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="inputMask"
        @keydown.enter="handleEnter"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </InputMask>
    </template>
  </FieldValidator>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { InputMask } from './../../../core/controls';
import { FieldValidator } from './../field-validator';
import useSubmitFormProvider from '../../../core/controls/composables/input/use-submit-form-provider';

defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  mask: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
});

const inputMask = ref<InstanceType<typeof InputMask>>();

const { handleEnter } = useSubmitFormProvider();

defineExpose({ inputMask });
</script>
