import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { OrdersRouteNames, OrdersRoutePathNames } from '../../../../../enums';

const Component = () => import('./OrderMoreInfo.vue');

export default {
  path: buildChildURL(OrdersRoutePathNames.MORE_INFO),
  name: OrdersRouteNames.ORDER_DETAILS_MORE_INFO,
  component: Component,
} as RouteRecordRaw;
