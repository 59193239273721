import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../../../enums';

const Component = () => import('./LocalBrandItems.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.ITEMS),
  name: LocalMenuGroupRouteNames.BRAND_ITEMS,
  component: Component,
} as RouteRecordRaw;
