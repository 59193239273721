import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../enums';
import menuHours from './components/menu-hours';
import pickupDetails from './components/pickup-details';
import dropoffDetails from './components/drop-off-details';
import salesChannel from './components/sales-channel';
import deviceMapping from './components/device-mapping';
import reporting from './components/reporting';
import kds from './components/kds';

const Component = () => import('./SiteDetails.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.SITE),
  name: SitesRouteNames.SITE_DETAILS,
  component: Component,
  redirect: { name: menuHours.name },

  children: [menuHours, pickupDetails, dropoffDetails, salesChannel, deviceMapping, reporting, kds],
} as RouteRecordRaw;
