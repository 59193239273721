<template>
  <div class="delete-zone flex flex-row flex-wrap">
    <div class="flex align-items-center justify-content-center label">
      {{ t(`deleteFooter.label`) }}
    </div>
    <div class="flex align-items-center justify-content-center">
      <Button class="p-button-link action" :label="btnLabel" @click="showDeleteDialog()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TagComponentSeverities, useConfirmDialog } from '@centric-os/components';
import { onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  actionLabel: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['delete']);

const { t } = useI18n();

const btnLabel = ref('');
const entityLabel = ref(props.actionLabel);

btnLabel.value = `${t(`deleteFooter.action`)} ${entityLabel.value}`;

const accept = () => {
  emit('delete');
};

const reject = () => console.debug('Cancel delete');

const { showConfirmDialog: showDeleteDialog, close } = useConfirmDialog({
  header: t(`deleteDialog.header`),
  message: t(`deleteDialog.messageAlert`),
  acceptLabel: t(`deleteDialog.acceptLabel`),
  rejectLabel: t(`deleteDialog.rejectLabel`),
  accept,
  reject,
  severity: TagComponentSeverities.DANGER,
});

onBeforeUnmount(() => {
  close();
});
</script>
