import { createAxiosInstance } from '@centric-os/platform';
import type { AxiosInstance } from 'axios';

// creating instance to query centricos.io BE API
const api: AxiosInstance = createAxiosInstance();

// creating instance to query api.compassdigital.org BE API
export const cdlApi: AxiosInstance = createAxiosInstance({
  baseURL: import.meta.env.VITE_CDL_ENDPOINT_BASE_URL,
});

// creating instance to query any generic API
export const genericAPI: AxiosInstance = createAxiosInstance({
  baseURL: '',
  skipAuthorization: true,
});

export default api;
