import { computed, watchEffect } from 'vue';
import useTextareaFocused from '../use-textarea-focus';
import useTextareaHovered from '../use-textarea-hover';
import useInputDisabled from '../../input/use-input-disabled';
import useTextareaRequired from '../use-textarea-required';

import type { InputAttrs } from '@centric-os/types';

export default (attrs: InputAttrs) => {
  const { focused, classes: fClasses } = useTextareaFocused();
  const { hovered, classes: hClasses } = useTextareaHovered();
  const { required, classes: rClasses } = useTextareaRequired(attrs.required);
  const { disabled, classes: dClasses } = useInputDisabled(attrs.disabled);

  watchEffect(() => {
    disabled.value = Boolean(attrs.disabled);
    required.value = Boolean(attrs.required);
  });

  const classes = computed(() => [
    'p-field',
    'p-textarea-filled',
    {
      'p-error': Boolean((attrs.class as string)?.includes('p-invalid')),
      ...fClasses.value,
      ...hClasses.value,
      ...rClasses.value,
      // ...dClasses.value,
    },
  ]);

  return {
    required,
    disabled,
    focused,
    hovered,
    classes,
  };
};
