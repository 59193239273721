<template>
  <div class="grid" id="table-layout">
    <template v-if="useHeader">
      <div class="col-12 md:col-9 pt-7 pb-5 bg-white">
        <h2 class="font-semibold pl-5-6"><slot name="title" /></h2>
      </div>

      <div class="col-12 md:col-3 bg-white">
        <slot name="actions" />
      </div>
    </template>

    <div class="col-12 py-0" id="table-section">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type PropType } from 'vue';
import type { MenuItem } from '@centric-os/types';
import { isNull } from 'lodash';
import { useContentPageStore } from '@centric-os/stores';
import { storeToRefs } from 'pinia';

defineProps({
  useHeader: {
    type: Boolean,
    default: true,
  },
  menuItems: {
    type: Object as PropType<MenuItem>,
  },
});

const cm = ref();

const onRowContextMenu = (event) => {
  cm.value.show(event);
};

defineExpose({ onRowContextMenu });

// const { finalHeight } = useContentFullHeight();

const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);

onMounted(() => {
  const parentCardBody: HTMLElement = document.querySelector('#main-content-card');
  if (!isNull(parentCardBody)) {
    parentCardBody
      .getElementsByClassName('p-card-body')[0]
      ?.setAttribute('style', 'height: 100%; padding-bottom: 0;');
    parentCardBody
      .getElementsByClassName('p-card-content')[0]
      ?.setAttribute('style', 'height: 100%');
  }
  setTimeout(() => {
    const tableContent: HTMLElement = document.querySelector('#table-section');
    tableContent?.setAttribute('style', `height: 100%`);
  }, 0);
});
</script>

<style lang="scss" scoped>
:deep(.p-virtualscroller) {
  height: 100% !important;
}
#table-layout {
  height: 100%;
}
:deep(.p-card) {
  &.card-full-viewport {
    height: v-bind('contentPageLayoutHeight');
    max-height: v-bind('contentPageLayoutHeight');

    .p-card-body {
      max-height: v-bind('contentPageLayoutHeight');
      overflow-y: auto;
    }
  }
}
</style>
