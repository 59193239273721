import { watch, toRaw } from 'vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';
import type { UserWithPermissions } from '@centric-os/types';
import { cdlApi } from '@centric-os/app/src/api';
import { version as app_version } from '../../../../package.json';
import { useAuthStore, useSiteStore } from '../../../stores';
import { storeToRefs } from 'pinia';
import { isNil } from 'lodash';

const stage = import.meta.env.VITE_STAGE;

const fieldIds = {
  dev: {
    Product: '25205894192151',
    'Browser Type': '25205909694999',
    'Browser Version': '25205967224087',
    'Browser Page': '21923202198807',
    'Device Model': '25205909733015',
    'Device Type': '25205921105687',
    'User Name': '25205967267607',
    'Preferred Contact Method': '25205939627543',
    'User Email': '25205950269079',
    'Site Name': '25205927299863',
    Country: '25205949693207',
    'App Version': '25205937328791',
    Platform: '25205921474583',
    'Source URL': '25205956467223',
  },
  prod: {
    Product: '30800457',
    'Browser Type': '21923133888663',
    'Browser Version': '21923171360151',
    'Browser Page': '21923202198807',
    'Device Model': '21923527005079',
    'Device Type': '34583907',
    'User Name': '21923571580695',
    'Preferred Contact Method': '360057628753',
    'User Email': '360023044353',
    'Site Name': '21923655338135',
    Country: '360010710634',
    'App Version': '34547628',
    Platform: '360012961234',
    'Source URL': '22738035693207',
  },
};

const ids = stage === 'v1' ? fieldIds.prod : fieldIds.dev;

let instance: ZendeskWidget;

declare let zE: any;

declare global {
  interface Window {
    zendesk: any;
  }
}

export class ZendeskWidget {
  private authStore = useAuthStore();
  private route = useRoute();
  private siteStore = useSiteStore();
  private loggedIn = false;

  async init(
    cdlUser: UserWithPermissions = null,
    route: RouteLocationNormalizedLoaded = this.route,
    siteStore: any = this.siteStore,
  ): Promise<void> {
    const { cdlUser: userSession } = storeToRefs(this.authStore);

    if (isNil(cdlUser) && userSession?.value) {
      cdlUser = userSession.value;
    }

    this.route = route;
    this.siteStore = siteStore;

    await this.waitForZendeskScript();

    this.watchForRouteChanges();
    this.watchForUserChanges();

    const browserInfo = this.getBrowserInfo(navigator.userAgent);
    const deviceInfo = this.getDeviceInfo(navigator.userAgent);

    this.setCommonConversationFields(browserInfo, deviceInfo);

    if (cdlUser) {
      this.setAuthenticatedUserConversationFields(cdlUser);
      await this.zendeskLoginUser();
    } else {
      this.zendeskLogoutUser();
    }
  }

  private async zendeskLoginUser() {
    const {
      data: { token: jwt },
    } = await cdlApi.post('user/zendesk');
    if (!this.loggedIn) {
      zE('messenger', 'loginUser', function (callback: any) {
        callback(jwt);
      });
      this.loggedIn = true;
    }
  }

  public zendeskLogoutUser() {
    if (this.loggedIn) {
      zE('messenger', 'logoutUser');
      this.loggedIn = false;
    }
  }

  private waitForZendeskScript(): Promise<void> {
    return new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        if (typeof zE !== 'undefined') {
          clearInterval(checkInterval);
          resolve();
        }
      }, 100);
    });
  }

  private watchForRouteChanges(): void {
    watch(
      () => this.route?.path,
      (newPath) => {
        const app_page = newPath.charAt(1).toUpperCase() + newPath.slice(2);
        zE('messenger:set', 'conversationFields', [{ id: ids['Browser Page'], value: app_page }]);
        zE('messenger:set', 'conversationFields', [
          { id: ids['Source URL'], value: window.location.hostname },
        ]);
      },
    );
  }

  private watchForUserChanges(): void {
    const { cdlUser: userSession } = storeToRefs(this.authStore);

    watch(userSession, async (newValue: UserWithPermissions) => {
      this.setAuthenticatedUserConversationFields(newValue);
      if (newValue) {
        await this.zendeskLoginUser();
      } else {
        this.zendeskLogoutUser();
      }
    });
  }

  private getBrowserInfo(userAgent: string): { browser_name: string; browser_version: string } {
    const browsers = [
      { name: 'Firefox', identifier: 'Firefox' },
      { name: 'Internet Explorer', identifier: ['MSIE', 'Trident'] },
      { name: 'Edge', identifier: 'Edg' },
      { name: 'Chrome', identifier: 'Chrome' },
      { name: 'Opera', identifier: ['Opera', 'OPR'] },
      { name: 'Safari', identifier: 'Safari', notIdentifier: 'Chrome' },
    ];

    for (let browser of browsers) {
      const identifiers = Array.isArray(browser.identifier)
        ? browser.identifier
        : [browser.identifier];
      for (let id of identifiers) {
        if (
          userAgent.includes(id) &&
          (!browser.notIdentifier || !userAgent.includes(browser.notIdentifier))
        ) {
          const versionMatch = userAgent.match(new RegExp(`${id}[\\/\\s](\\d+)`)) || [];
          return { browser_name: browser.name, browser_version: versionMatch[1] || 'Unknown' };
        }
      }
    }
    return { browser_name: 'Unknown', browser_version: 'Unknown' };
  }

  private getDeviceInfo = (userAgent: string): { device_model: string; device_type: string } => {
    let device_model = 'Unknown';
    let device_type = 'Desktop';

    // Device Type
    if (/mobile/i.test(userAgent)) {
      device_type = 'Mobile';
    } else if (/tablet/i.test(userAgent)) {
      device_type = 'Tablet';
    }

    // Device Model
    if (/iPhone/i.test(userAgent)) {
      device_model = 'iPhone';
    } else if (/Android/i.test(userAgent)) {
      device_model = 'Android Device';
    } else if (/Windows/i.test(userAgent)) {
      device_model = 'Windows Device';
    } else if (/Macintosh/i.test(userAgent)) {
      device_model = 'Mac Device';
    }

    const deviceInfo = {
      device_model,
      device_type,
    };

    return deviceInfo;
  };

  private setCommonConversationFields(
    browserInfo: {
      browser_name: string;
      browser_version: string;
    },
    deviceInfo: {
      device_model: string;
      device_type: string;
    },
  ): void {
    zE('messenger:set', 'conversationFields', [
      { id: ids['Product'], value: 'centricos_app' },
      { id: ids['Browser Type'], value: browserInfo.browser_name },
      { id: ids['Browser Version'], value: browserInfo.browser_version },
      { id: ids['Device Model'], value: deviceInfo.device_model },
      { id: ids['Device Type'], value: deviceInfo.device_type },
      { id: ids['App Version'], value: app_version },
      { id: ids['Country'], value: 'can' },
      { id: ids['Platform'], value: 'mobile_app' },
    ]);
  }

  private async setAuthenticatedUserConversationFields(
    cdlUser: UserWithPermissions,
  ): Promise<void> {
    if (cdlUser) {
      const siteStore = useSiteStore();
      watch(
        () => siteStore.sites,
        (sites) => {
          // Setting up user sites
          const siteNames = toRaw(sites).map((site) => site.name);
          const siteNamesString = siteNames.join('\n');

          // Set Authenticated user fields
          zE('messenger:set', 'conversationFields', [
            { id: ids['User Name'], value: cdlUser.name.first + ' ' + cdlUser.name.last },
            { id: ids['Preferred Contact Method'], value: 'email_message' },
            { id: ids['User Email'], value: cdlUser.email },
            { id: ids['Site Name'], value: siteNamesString || '' },
          ]);
        },
      );
    } else {
      zE('messenger:set', 'conversationFields', [
        { id: ids['User Name'], value: '' },
        { id: ids['Preferred Contact Method'], value: 'email_message' },
        { id: ids['User Email'], value: '' },
        { id: ids['Site Name'], value: '' },
      ]);
    }
  }

  public hideWidget = (): void => {
    if (typeof zE === 'undefined') {
      console.error('zE is not initialized');
      return;
    }
    zE('messenger', 'hide');
  };
  public showWidget = (): void => {
    if (typeof zE === 'undefined') {
      console.error('zE is not initialized');
      return;
    }
    zE('messenger', 'show');
  };
}

export function zendesk(): ZendeskWidget {
  if (!instance) {
    instance = new ZendeskWidget();
  }
  return instance;
}
