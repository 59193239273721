import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { LibrariesRoutePathNames, LibrariesRouteNames } from './enums';
import routes from './routes';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(LibrariesRoutePathNames.LIBRARIES),
  name: LibrariesRouteNames.LIBRARIES_ROOT,
  meta: { requireAuth: true },
  component: App,
  children: routes,
};
