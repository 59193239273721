<template>
  <div :class="rootClasses">
    <label v-if="$slots.label">
      <slot name="label" />
    </label>

    <PVMultiSelect
      v-model="value"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    />

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import PVMultiSelect from 'primevue/multiselect';
import { useAttrs } from 'vue';
import { useInputState } from '../composables';

const attrs = useAttrs();

const value = defineModel<any>();

const { focused, hovered, classes: rootClasses } = useInputState(attrs);

defineExpose({
  value,
});
</script>
