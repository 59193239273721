<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="c-page">
    <slot />
  </section>
</template>

<script setup lang="ts">
import { inject, provide, watch } from 'vue';
import { useTitle } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { DocumentTitleLocaleKey, SetDocumentTitleKey } from './injection-keys';
const props = defineProps({
  title: String,
  wrapTitle: {
    type: Boolean,
    default: true,
  },
});

const title = useTitle();
const { interpolationProperty = 'title', wrapperKey } = inject(DocumentTitleLocaleKey, {});

const { t } = useI18n();
const setDocumentTitle = (newTitle: string | null | undefined) => {
  if (props.wrapTitle && wrapperKey) {
    newTitle = t(wrapperKey, {
      [interpolationProperty]: newTitle,
    });
  }

  title.value = newTitle;
};

provide(SetDocumentTitleKey, setDocumentTitle);

watch(
  () => props.title as string,
  (newValue: string, oldValue: string) => {
    if (newValue !== oldValue) {
      setDocumentTitle(newValue);
    }
  },
  { immediate: true },
);
</script>
