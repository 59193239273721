<template>
  <Page :title="title" class="c-content-page">
    <section id="container-parent">
      <section
        v-if="!noHeader"
        id="main-content-header"
        ref="mainContentHeaderRef"
        class="sticky top-0 z-1"
      >
        <template v-if="loading">
          <Transition name="fade">
            <div class="pt-4">
              <Skeleton width="15%" class="mb-3 mx-4" />

              <section id="loading-record-header" class="c-content-header px-4">
                <Grid class="grid-nogutter">
                  <Col3 class="pr-3">
                    <Skeleton />
                  </Col3>
                </Grid>

                <Grid class="grid-nogutter mt-2">
                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>
                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>
                </Grid>

                <Grid class="mt-2">
                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>

                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>

                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>

                  <Col1>
                    <Skeleton width="70%" />
                  </Col1>
                  <Col12 class="py-0">
                    <div class="w-full border-bottom-1 border-300" />
                  </Col12>
                </Grid>
              </section>
            </div>
          </Transition>
        </template>

        <template v-else>
          <Transition name="fade">
            <div>
              <div class="c-page-breadcrumb">
                <slot name="breadcrumb" />
              </div>

              <template v-if="$slots.header">
                <section id="custom-record-header" class="c-content-header px-4">
                  <slot name="header"></slot>
                </section>
              </template>

              <template v-else-if="title || actionLabel">
                <section id="record-header" class="c-content-header">
                  <div class="flex justify-content-between flex-wrap">
                    <div class="flex flex-column justify-content-start title">
                      <h1 class="font-semibold">{{ title }}</h1>

                      <div class="flex align-items-center justify-content-start mt-2">
                        <!-- Ignore lint error, as Vue3 accepts key on template -->
                        <template
                          v-for="(tagComponent, i) of tagComponents"
                          :key="getTagRenderKey(tagComponent, i)"
                        >
                          <component
                            :is="tagComponent?.tagComponentName"
                            v-bind="tagComponent?.attrs"
                          >
                            {{ tagComponent?.attrs.label }}
                          </component>
                        </template>
                      </div>
                    </div>

                    <div class="flex align-items-center justify-content-center actions">
                      <template v-if="$slots.actions">
                        <slot name="actions"></slot>
                      </template>
                      <template v-else>
                        <Button
                          v-if="actionLabel"
                          :label="actionLabel"
                          @click="$emit('action')"
                          :disabled="actionDisabled"
                        />
                      </template>
                    </div>
                  </div>
                </section>
              </template>

              <slot name="tabmenu" />
            </div>
          </Transition>
        </template>

        <template v-if="!$slots.tabmenu">
          <Transition name="fade">
            <div class="w-full border-bottom-1 border-300" />
          </Transition>
        </template>
      </section>

      <Transition name="fade">
        <section class="c-page-container z-0">
          <div class="c-page-content">
            <slot></slot>
          </div>
        </section>
      </Transition>
    </section>
  </Page>
</template>

<script setup lang="ts">
import { ref, type PropType, watch } from 'vue';
import { Page } from './../../../core/containers/pages/page';
import { Button } from '../../../core/buttons/button';
import { Skeleton } from './../../../core/misc';
import type { TagComponentProp } from '@centric-os/types';
import { useElementSize } from '@vueuse/core';
import { useContentPageStore } from '@centric-os/stores';
const mainContentHeaderRef = ref();
const { height: mainContentHeaderHeight } = useElementSize(mainContentHeaderRef);

const props = defineProps({
  title: {
    type: String,
  },
  actionLabel: {
    type: String,
  },
  actionDisabled: {
    type: Boolean,
    default: false,
  },
  tagComponents: {
    type: Object as PropType<TagComponentProp[]>,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  noHeader: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['action']);

const getTagRenderKey = (tagComponent: TagComponentProp, index: number): string => {
  return `${tagComponent?.tagComponentName}-${index}`;
};

const contentPageStore = useContentPageStore();

watch(
  mainContentHeaderHeight,
  (newVal) => {
    let height = newVal; // Will equate to 0 if props.noHeader is true
    const titleBarHeader: HTMLElement = document.querySelector('#title-bar');
    if (titleBarHeader) {
      height += titleBarHeader?.offsetHeight;
    }
    contentPageStore.contentPageHeaderHeight = height;
  },
  { immediate: props.noHeader },
);
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
