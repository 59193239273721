import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { ReportsRoutePathNames, ReportsRouteNames } from './enums';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(ReportsRoutePathNames.REPORTS),
  name: ReportsRouteNames.REPORTS,
  redirect: {
    name: ReportsRouteNames.SALES_SUMMARY,
  },
  meta: {
    requireAuth: true,
  },
  component: App,
  children: routes,
};
