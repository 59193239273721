import { useI18n } from 'vue-i18n';

export default () => {
  const { t } = useI18n();

  const translate = (key: string, ...args: any[]) => {
    // Custom logic can be added here
    return t(key, ...(args as [any]));
  };

  return { translate };
};
