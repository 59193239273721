export enum LocalMenuGroupRouteNames {
  LOCAL_MENUS_ROOT = 'MenuRoot',
  LOCAL_MENUS = 'MenuRoot/Menus',
  LOCAL_MENUS_LIST = 'MenuRoot/Menus/List',
  LOCAL_MENU_DETAILS = 'MenuRoot/Menus/Details',
  BRAND_DETAILS = 'MenuRoot/Menus/Brand/Details',
  BRAND_MENUS = 'MenuRoot/Menus/Brand/Details/Views',
  BRAND_ITEMS = 'MenuRoot/Menus/Brand/Details/Items',
  BRAND_MODIFIERS = 'MenuRoot/Menus/Brand/Details/Modifiers',
  BRAND_UPDATES = 'MenuRoot/Menus/Brand/Details/Updates',
  BRAND_JWO_AUDIT = 'MenuRoot/Menus/Brand/Details/Audit',
}
