import { computed, watchEffect } from 'vue';
import useInputFocused from '../use-input-focus';
import useInputHovered from '../use-input-hover';
import useInputDisabled from '../use-input-disabled';
import useInputRequired from '../use-input-required';
import useInputStyle from '../use-input-style';

import type { InputAttrs } from '@centric-os/types';

export type ClassType = string | { [key: string]: boolean };

export default (attrs: InputAttrs) => {
  const { focused, classes: fClasses } = useInputFocused();
  const { hovered, classes: hClasses } = useInputHovered();
  const { required, classes: rClasses } = useInputRequired(attrs.required);
  const { disabled, classes: dClasses } = useInputDisabled(attrs.disabled);
  const { classes: sClasses } = useInputStyle(attrs.customClass);

  watchEffect(() => {
    disabled.value = Boolean(attrs.disabled);
    required.value = Boolean(attrs.required);
  });

  const classes = computed<ClassType[]>(() => [
    'p-field',
    'w-full',
    {
      'p-error': attrs.class?.includes('p-invalid'),
      ...fClasses.value,
      ...hClasses.value,
      ...rClasses.value,
      // ...dClasses.value,
      ...sClasses.value,
    },
  ]);

  return { required, disabled, focused, hovered, classes };
};
