<template>
  <div class="grid">
    <div class="col-12 lg:col-8">
      <slot name="leftColumn" />
    </div>

    <div class="col-12 lg:col-4">
      <slot name="rightColumn" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
