import { defineStore, type StateTree } from 'pinia';
import ID from '@compassdigital/id';
import type { DecodedID } from '@compassdigital/id/interface';
import type { MultiGroup, Multigroups } from '@compassdigital/sdk.typescript/interface/location';

interface State extends StateTree {
  multigroups: MultiGroup[];
}

/**
 * Pinia store for managing MultiGroups.
 */
export function useMultigroupStore(storeId = 'multigroup') {
  const store = defineStore(storeId, {
    state: (): State => ({
      multigroups: [],
    }),
    actions: {
      /**
       * Fetch MultiGroups from the API and filter them based on specific criteria.
       */
      async getMultiGroups(): Promise<void> {
        if (this.multigroups.length === 0) {
          const response = await this.cdlApi.get<Multigroups>('/location/multigroup');

          this.multigroups = this.filterMultigroups(response.data?.multigroups ?? []);
        }
      },
      /**
       * Filter MultiGroups based on name and provider.
       * @param multigroups - The list of multigroups to filter.
       * @returns The filtered list of multigroups.
       */
      filterMultigroups(multigroups: MultiGroup[]): MultiGroup[] {
        return multigroups
          .filter((m: MultiGroup) => m.name !== 'BAMCO' && m.name !== 'BoostSelect')
          .filter((m: MultiGroup) => {
            const decodedID: DecodedID = ID(m.id);
            return !m.name.match(/unit_test/) && decodedID.provider === 'cdl';
          });
      },
    },
    getters: {
      /**
       * Get the IDs of the multigroups as a JSON string.
       * @param state - The store state.
       * @returns A JSON string of the multigroup IDs.
       */
      getMultigroupIDs: (state): string => JSON.stringify(state.multigroups.map((m) => m.id)),
    },
    pagination: false,
  });

  return store();
}
