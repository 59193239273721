import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../../enums';
const Component = () => import('./DropoffDetails.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.DROPOFF_DETAILS),
  name: SitesRouteNames.SITE_DROPOFF_DETAILS,
  component: Component,
} as RouteRecordRaw;
